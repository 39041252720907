import React, { useEffect, useState } from 'react'
import { Alert, Box, CircularProgress, Grid, InputLabel, TextField, Typography } from '@mui/material'
import { postCandidateReference, deleteCandidateReference, updateCandidateReference } from '../../../../../fetchAPI'
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal'
import CandidateUpdateModal from './component/CandidateUpdateModal'
import customStyles from './CandidateUpdateProfile.module.css'
import EditSVG from './component/EditSVG'
import DeleteSVG from './component/DeleteSVG'
import AddButton from './component/AddButton'
import { useAlert } from '../../../../context/AlertContext'
import RequiredAsterisk from './component/RequiredAsterisk'
import { validatePhoneNumber } from '../../../../helpers/helperFunctions'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

// Custom breakpoint at 768px
const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        md: 768,
        lg: 992,  
      },
    },
  });

const useStyles = makeStyles({
    input: {
      borderLeft: '1px solid #C4C4C4',
      paddingLeft: "14px",
      marginLeft: "7px",
      padding: "30px 0px",
    },
    outlinedInput: {
      '& .react-tel-input .form-control': {
        borderRadius: '4px',
        borderColor: '#C4C4C4',
        padding: '14px',
        fontSize: '16px',
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '& .react-tel-input .flag-dropdown': {
        border: 'none',
        backgroundColor: 'transparent',
      },
      '& .react-tel-input .form-control:focus': {
        borderColor: '#0275D8',
        boxShadow: '0 0 0 1px #0275D8',
      },
      '& .react-tel-input .selected-flag': {
        marginLeft: '8px',
        marginRight: '12px',
      },
      '& .react-tel-input .country-list': {
        zIndex: 1000,
      },
    }
  });

function UpdateReference(props) {
    const [referenceAddData, setReferenceAddData] = useState({})
    const [updateReferenceData, setUpdateReferenceData] = useState({})

    const [loader, setLoader] = useState(false)

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);

    const alertContext = useAlert();
    const [phoneNumber, setPhoneNumber] = useState("")
    const [country, setCountry] = useState("bd")
    const [isBD, setIsBD] = useState(false);
    const [dialCode, setDialCode] = useState("");
    const classes = useStyles();

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [deleteItemId, setDeleteItemId] = useState('')
    let errorsObj = {};
    const [errors, setErrors] = useState({})
    const [updateErrors, setUpdateErrors] = useState({})
    const { search } = useLocation();

	useEffect(() => {
	  const scrollToHash = () => {
		const params = new URLSearchParams(search);
		const scrollTo = params.get('scrollTo');
		if (scrollTo) {
		  setTimeout(() => {
			const element = document.getElementById(scrollTo);
			if (element) {
				const offset = element.offsetTop - 220;
				window.scrollTo({ top: offset, behavior: 'smooth' });
			}
		  }, 100);
		}
	  };
  
	  scrollToHash();
	  window.addEventListener('popstate', scrollToHash);

	  return () => {
		window.removeEventListener('popstate', scrollToHash);
	  };
	}, [search]);

    const handlePhoneChange = (value, countryData) => {
        setDialCode(countryData.dialCode); 

        if(country !== countryData.countryCode) {
            setPhoneNumber('');
            setCountry(countryData.countryCode);
        }
        
        if(countryData.countryCode === "bd"){
            setIsBD(true)
        } else {
            setIsBD(false)
        }
        setPhoneNumber(value)
        setReferenceAddData({...referenceAddData, mobile_number: value})
    };

    const handleUpdatePhoneChange = (value, countryData) => {
        setDialCode(countryData.dialCode); 

        if(country !== countryData.countryCode) {
            setCountry(countryData.countryCode);
        }
        
        if(countryData.countryCode === "bd"){
            setIsBD(true)
        } else {
            setIsBD(false)
        }
        setUpdateReferenceData({...updateReferenceData, mobile_number: value})
    };

    const enableUpdate = (item) => {
        setUpdateReferenceData({
            id: item.id,
            name: item.name,
            designation: item.designation,
            relation: item.relation,
            mobile_number: item.dial_code + item.mobile_number,
            email: item.email,
            organization: item.organization,
            dialCode: item.dial_code
        })
    }

    const handleUpdateChange = (name) => (event) => {
        setUpdateReferenceData({
            ...updateReferenceData,
            [name]: event.target.value
        })
    }

    const handleChange = (name) => (event) => {
        setReferenceAddData({ ...referenceAddData, [name]: event.target.value })
    }

    const insertCandidateReference = (body) => {
        let error = false;
        const errorObj = { ...errorsObj };

        if (!body.name) {
            errorObj.name = 'Name is Required';
            error = true;
        }
        if (!body.designation) {
            errorObj.designation = 'Designation is Required';
            error = true;
        }
        if (!body.email) {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if(body.email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(body.email)) {
                errorObj.email = 'Email is Invalid';
                error = true;             
            }
        }
        setErrors(errorObj);
        if (error) {
            setLoader(false);
        }
        else {
            const actualNumber = body.mobile_number?.replace(`${dialCode}`, isBD ? "0" : "")
            const params = {...body, mobile_number: actualNumber != "0" ? actualNumber : "", dialCode: actualNumber && actualNumber != "0" ? dialCode : ""}

            if (isBD && actualNumber != "0" && !validatePhoneNumber(actualNumber)) {
              alertContext.setOpenErrorAlert(true);
              alertContext.setResponseMessage("Please enter a valid phone number!");
              setLoader(false);
              return;
            }

            setAddModalOpen(false);
            setReferenceAddData({});
            postCandidateReference(params).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                    setPhoneNumber("");
                    setCountry("bd");
                    setDialCode("");
                }
                else {
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false)
                }
            })
        }
    }

    const handleOpen = (itemId) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const deleteReference = (id) => {
        setOpenDeleteModal(false)
        setLoader(true)
        deleteCandidateReference(id).then((response) => {
            if (response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                props.renderState()
                setLoader(false)
            }
            else {
                console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
                setLoader(false);
            }
        })
    }

    const updateReference = (body) => {
        let error = false;
        const errorObj = { ...errorsObj };

        if (!body.name) {
            errorObj.name = 'Name is Required';
            error = true;
        }
        if (!body.designation) {
            errorObj.designation = 'Designation is Required';
            error = true;
        }
        if (!body.email) {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if(body.email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(body.email)) {
                errorObj.email = 'Email is Invalid';
                error = true;             
            }
        }
        setUpdateErrors(errorObj);
        if (error) {
            setLoader(false);
        }
        else {
            let actualNumber;
            if(dialCode) {
                actualNumber = body.mobile_number?.replace(`${dialCode}`, isBD ? "0" : "")
            } else {
                actualNumber = body.mobile_number
            }
            
            const params = {...body, mobile_number: actualNumber != "0" ? actualNumber : "", dialCode: actualNumber && actualNumber != "0" ? dialCode : ""}

            if (isBD && actualNumber != "0" && !validatePhoneNumber(actualNumber)) {
              alertContext.setOpenErrorAlert(true);
              alertContext.setResponseMessage("Please enter a valid phone number!");
              setLoader(false);
              return;
            }
            setUpdateModalOpen(false);
            setUpdateReferenceData({})
            updateCandidateReference(params.id, params).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                    setPhoneNumber("")
                    setDialCode("")
                    setCountry("bd")
                }
                else {
                    console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }

    return (
        <Box id='candidate_update_reference_box'>
            <Box className='card shadow-sm'>
                <Box className={customStyles.cardHeader}>
                    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Typography sx={{fontSize: "18px", fontWeight: 700, color: "#424447"}}>References</Typography>
                        <Box
                            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "#0275D8", fontFamily: "Epilogue", fontWeight: "600 !important", fontSize: "16px !important", backgroundColor: "#E5F5FF", padding: "6px 16px !important", cursor: "pointer" }}
                            onClick={() => setAddModalOpen(true)}
                        >
                            <Typography sx={{color: "#0275D8", marginRight: "10px", fontFamily: "Epilogue", fontSize: "16px", fontWeight: 600}}>Add</Typography>
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="20" rx="10" fill="#CAEBFF"/>
                                <path d="M6.99362 9.80457C6.27657 9.80457 5.56145 9.80457 4.8444 9.80457C4.40448 9.80457 4.12153 10.0836 4.13316 10.4848C4.13603 10.6259 4.18635 10.7619 4.27601 10.8709C4.36567 10.9799 4.48944 11.0556 4.62735 11.0856C4.72962 11.1044 4.83344 11.1135 4.93742 11.1127H9.07696C9.38122 11.1127 9.34052 11.072 9.34246 11.386C9.34246 12.7852 9.34246 14.1844 9.34246 15.5836C9.34246 16.041 9.61378 16.3298 10.0188 16.3201C10.1598 16.3184 10.2961 16.2693 10.4058 16.1807C10.5155 16.0921 10.5921 15.9692 10.6235 15.8317C10.6438 15.7296 10.6535 15.6257 10.6525 15.5216C10.6525 14.1224 10.6525 12.7212 10.6525 11.322C10.6525 11.1495 10.6952 11.105 10.8677 11.1069C12.2669 11.1069 13.6661 11.1069 15.0673 11.1069C15.1647 11.1089 15.2621 11.1011 15.358 11.0836C15.5021 11.0525 15.6313 10.9729 15.7239 10.8582C15.8166 10.7434 15.8671 10.6003 15.8671 10.4528C15.8671 10.3053 15.8166 10.1623 15.7239 10.0475C15.6313 9.93272 15.5021 9.85314 15.358 9.82201C15.2558 9.80289 15.1518 9.79445 15.0479 9.79682H10.9277C10.6099 9.79682 10.6545 9.84139 10.6545 9.51388C10.6545 8.14178 10.6545 6.7684 10.6545 5.39372C10.6564 5.29629 10.6487 5.1989 10.6312 5.10302C10.6019 4.9568 10.5228 4.82525 10.4075 4.73073C10.2921 4.63621 10.1476 4.58456 9.99847 4.58456C9.84934 4.58456 9.70481 4.63621 9.58945 4.73073C9.4741 4.82525 9.39504 4.9568 9.36572 5.10302C9.34988 5.19908 9.34275 5.29638 9.3444 5.39372C9.3444 6.79294 9.3444 8.19411 9.3444 9.59333C9.3444 9.75612 9.30177 9.8007 9.13897 9.79876C8.4258 9.80457 7.70874 9.80457 6.99362 9.80457Z" fill="#0079C1"/>
                                </svg>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className='card-body'>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            props.candidateReference?.map((item, index) => {
                                return (
                                    <Box key={item.id}>
                                        {index > 0 ? <hr /> : ''}
                                        <Box className={customStyles.singleRef} >
                                        <Typography sx={{fontSize: "18px", fontWeight: 700, color: "#0079c1"}}>{item.name}</Typography>

                                            <Box className={customStyles.actionContainer} style={{ marginLeft: '10px' }}>
                                                <span style={{ height: '32px' }} onClick={() => { enableUpdate(item); setUpdateModalOpen(true) }}>
                                                    <EditSVG />
                                                </span>
                                                <span style={{ height: '32px' }} onClick={() => handleOpen(item.id)}>
                                                    <DeleteSVG />
                                                </span>
                                            </Box>
                                        </Box>
                                        <Typography sx={{fontSize: "14px", fontWeight: 600, color: "#424447"}}>
                                            {item.designation} | {item.organization}
                                        </Typography>
                                        <Typography className={customStyles.refMobileNo}>
                                            {item.dial_code && item.mobile_number ? `${"+" + item.dial_code + item.mobile_number} |` : ""} {item.email}
                                        </Typography>
                                    </Box>
                                )
                            })
                    }
                </Box>
            </Box>
            {/* reference add fields */}
            <CandidateUpdateModal form="candidateReferenceAddForm" open={addModalOpen} onClose={() => [setErrors({}), setAddModalOpen(false), setReferenceAddData({}), setPhoneNumber(""), setDialCode(""), setCountry("bd")]} title='Add References'>
                <ThemeProvider theme={theme}>
                    <Box className='card-body'>
                        <form id='candidateReferenceAddForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); insertCandidateReference(referenceAddData); }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputLabel>Name<RequiredAsterisk/></InputLabel>
                                    <TextField required className='form-control' onChange={handleChange('name')} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} placeholder='Enter Name' />
                                    {errors.name && <Alert severity="error">{errors.name}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputLabel>Designation<RequiredAsterisk/></InputLabel>
                                    <TextField required className='form-control' onChange={handleChange('designation')} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} placeholder='Enter designation' />
                                    {errors.designation && <Alert severity="error">{errors.designation}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputLabel>Email Address<RequiredAsterisk/></InputLabel>
                                    <TextField required className='form-control' onChange={handleChange('email')} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} placeholder='Enter email' />
                                    {errors.email && <Alert severity="error">{errors.email}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputLabel>Relation</InputLabel>
                                    <TextField className='form-control' onChange={handleChange('relation')} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} placeholder='Enter relation' />
                                    {errors.relation && <Alert severity="error">{errors.relation}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputLabel>Mobile Number</InputLabel>									
                                    <PhoneInput
                                        country={country}
                                        value={phoneNumber || ''}
                                        onChange={handlePhoneChange}
                                        inputProps={{
                                            name: 'mobile_number',
                                            required: true,
                                            // autoFocus: true,
                                        }}
                                        countryCodeEditable={false}
                                        enableSearch={true}
                                        containerClass={classes.outlinedInput}
                                        inputStyle={{
                                            width: `calc(100% - 20px)`,
                                            border: '1px solid #C4C4C4',
                                            borderRadius: '4px',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: 'rgba(0, 0, 0, 0.87)',
                                            outline: 'none',
                                            paddingTop: '24.3px',
                                            paddingBottom: "24.3px",
                                            marginLeft: "20px",
                                        }}
                                            searchStyle={{width: "90%"}}
                                            dropdownStyle={{width: `${window.innerWidth > 560 ? "600%" : "400%"}`}}
                                        />	
                                        {errors.mobile_number && <Alert severity="error">{errors.mobile_number}</Alert>}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <InputLabel>Organization</InputLabel>
                                        <TextField className='form-control' onChange={handleChange('organization')} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} placeholder='Enter organization' />
                                        {errors.organization && <Alert severity="error">{errors.organization}</Alert>}
                                    </Grid>
                            </Grid>
                        </form>
                    </Box>
                </ThemeProvider>
            </CandidateUpdateModal>

            {/* reference update fields */}
            <CandidateUpdateModal form="candidateReferenceUpdateForm" open={updateModalOpen} onClose={() => [setUpdateErrors({}), setUpdateModalOpen(false), setUpdateReferenceData({}), setPhoneNumber(""), setDialCode(""), setCountry("bd")]} title='Update References'>
                <ThemeProvider theme={theme}>
                    <Box className='card-body'>
                        <form id='candidateReferenceUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateReference(updateReferenceData); }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputLabel>Name<RequiredAsterisk/></InputLabel>
                                    <TextField required className='form-control' value={updateReferenceData.name} onChange={handleUpdateChange('name')} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} placeholder='Enter Name' />
                                    {updateErrors.name && <Alert severity="error">{updateErrors.name}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputLabel>Designation<RequiredAsterisk/></InputLabel>
                                    <TextField required className='form-control' value={updateReferenceData.designation} onChange={handleUpdateChange('designation')} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} placeholder='Enter designation' />
                                    {updateErrors.designation && <Alert severity="error">{updateErrors.designation}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputLabel>Email Address<RequiredAsterisk/></InputLabel>
                                    <TextField required className='form-control' value={updateReferenceData.email} onChange={handleUpdateChange('email')} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} placeholder='Enter email' />
                                    {updateErrors.email && <Alert severity="error">{updateErrors.email}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputLabel>Relation</InputLabel>
                                    <TextField className='form-control' value={updateReferenceData.relation} onChange={handleUpdateChange('relation')} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} placeholder='Enter relation' />
                                    {updateErrors.relation && <Alert severity="error">{updateErrors.relation}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputLabel>Mobile Number</InputLabel>
                                    <PhoneInput
                                        country={country}
                                        value={updateReferenceData.mobile_number || ''}
                                        onChange={handleUpdatePhoneChange}
                                        inputProps={{
                                            name: 'mobile_number',
                                            required: true,
                                            // autoFocus: true,
                                        }}
                                        placeholder=""
                                        countryCodeEditable={false}
                                        enableSearch={true}
                                        containerClass={classes.outlinedInput}
                                        inputStyle={{
                                            width: `calc(100% - 20px)`,
                                            border: '1px solid #C4C4C4',
                                            borderRadius: '4px',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: 'rgba(0, 0, 0, 0.87)',
                                            outline: 'none',
                                            paddingTop: '24.3px',
                                            paddingBottom: "24.3px",
                                            marginLeft: "20px",
                                        }}
                                            searchStyle={{width: "90%"}}
                                            dropdownStyle={{width: `${window.innerWidth > 560 ? "600%" : "400%"}`}}
                                    />									
                                    {updateErrors.mobile_number && <Alert severity="error">{updateErrors.mobile_number}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputLabel>Organization</InputLabel>
                                    <TextField className='form-control' value={updateReferenceData.organization} onChange={handleUpdateChange('organization')} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} placeholder='Enter organization' />
                                    {updateErrors.organization && <Alert severity="error">{updateErrors.organization}</Alert>}
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </ThemeProvider>
            </CandidateUpdateModal>

            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deleteReference(deleteItemId)} />
        </Box>
    )
}

export default UpdateReference