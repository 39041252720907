import React, { createContext, useState, useEffect } from "react"
import { getEmployerBenefitList, getJobBenefitList, postSearchLogs } from "../../fetchAPI";
import { useJobSearchHook } from "../CustomHooks/jobSearchHook";
import { useQuery } from "react-query";
import { fetchIpAddress } from "../helpers/helperFunctions";
import Cookies from "js-cookie";

export const JobSearchContext = createContext();

const JobSearchContextProvider = ({ children }) => {

    const benefitInitialState = {
        job_benefit: [],
        employer_benefit: []
    }

    const [benefits, setBenefits] = useState(benefitInitialState);
    const [jobResults, setJobResults] = useState([]);
    const [jobSearchData, setJobSearchData] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [jobIndexRef, setJobIndexRef] = useState();
    const [urlFilteredLoader, setUrlFilteredLoader] = useState(false);
    const [isURLFiltered, setIsURLFiltered] = useState(false);
    const [callToBeMadeOnce, setCallToBeMadeOnce] = useState(false);

    const handleActivityLogs = async (filteredData) => {
        const ipAddress = await fetchIpAddress();
        const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

        const params = {
            key_values: new URLSearchParams(filteredData).toString(),
            ip: ipAddress,
            platform: 'web',
            tracking_page: 'search',
            ...(user?.id ? { user_id: user.id } : {}), // Conditionally add user_id
        }

        postSearchLogs(params).then((response) => {
            if (response && response.success) {
                // console.log(response)
            }
            else {
                console.log(response)
            }
        })
            .catch(err => {
                console.log(err)
            })
    }

    const handleReset = (isCxo, resetButtontrigger, location) => {
        setCallToBeMadeOnce(false);
        if (jobSearchData !== undefined) {
            if (jobSearchData !== null) {
                setBenefits(benefitInitialState)
                if (isCxo == true) {
                    setJobSearchData({ is_cxo: true })
                    if (resetButtontrigger || location?.state?.fromHeader) {
                        setJobResults([])
                        setPageNumber(1);
                    }
                }
                else {
                    setJobSearchData(null)
                    setJobResults([])
                    setPageNumber(1);
                }
            }
        }
    }

    const handleSearch = (data) => {

        setCallToBeMadeOnce(false);
        setJobResults([])
        setPageNumber(1);

        const filteredData = Object.fromEntries(
            Object.entries(data).filter(([key, value]) => value !== null && value !== '' && value !== undefined)
        );

        if ('job_benefit' in filteredData) {
            // Create a new object that only includes filteredData keys
            setJobSearchData({ ...jobSearchData, ...filteredData });
        }
        else {
            setJobSearchData(filteredData);
        }

        // Call Activity Log API 
        handleActivityLogs(filteredData)
    };

    // Custom hook to fetch jobs
    const { jobs, isLoading, status } = useJobSearchHook(jobSearchData, pageNumber);

    useEffect(() => {
        setCallToBeMadeOnce(false);
    }, [pageNumber, jobSearchData]);


    useEffect(() => {
        if (jobs?.hits?.hits && !isURLFiltered && !callToBeMadeOnce) {
            // Filter out jobs that already exist in jobResults
            const newJobs = jobs.hits.hits.filter(
                (job) => !jobResults.some(existingJob => existingJob._id === job._id)
            );

            if (newJobs.length > 0) {
                setJobResults(prev => [
                    ...(prev || []),
                    ...newJobs
                ]);
                setCallToBeMadeOnce(true);
            }
        }
    }, [jobs, jobSearchData]);


    // Job Benefit list fetch
    const { data: jobBenefitListResults } = useQuery('Job Benefits List', getJobBenefitList, { staleTime: Infinity })
    const { data: employerBenefitListResults } = useQuery('Employer Benefits List', getEmployerBenefitList, { staleTime: Infinity })

    return (
        <JobSearchContext.Provider value={{
            jobBenefitList: jobBenefitListResults ? jobBenefitListResults.response.data : [],
            employerBenefitList: employerBenefitListResults ? employerBenefitListResults.response.data : [],
            jobSearchData,
            setJobSearchData,
            benefits,
            setBenefits,
            pageNumber,
            setPageNumber,
            handleSearch,
            handleReset,
            jobs,
            jobResults,
            isLoading,
            status,
            jobIndexRef,
            setJobIndexRef,
            setJobResults,
            setIsURLFiltered,
            urlFilteredLoader,
            setUrlFilteredLoader
        }}>
            {children}
        </JobSearchContext.Provider>
    );
};

export default JobSearchContextProvider;