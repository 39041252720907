import React, { useContext, useState } from 'react'
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom'
import {Box, Button, ClickAwayListener, Fade, Tooltip, tooltipClasses } from '@mui/material';
import { EmployerContext } from '../../../context/EmployerContext';
import { useAlert } from '../../../context/AlertContext';
import { deleteEmployerSaveCandidateInfo, postEmployerSaveCandidateInfo } from '../../../../fetchAPI';
import LinkIcon from '@mui/icons-material/Link';
import MaskingRoundedImage from '../../maskingcomponents/MaskingRoundedImage';
import CommonMasking from '../../maskingcomponents/CommonMasking';
import styles from './UpdateProfile/CandidateUpdateProfile.module.css'
import { LoadingButton } from '@mui/lab';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip
        disableFocusListener
        disableHoverListener
        disableTouchListener
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#00A264',
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: '4px',

    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: '80%',
        width: '100%',
        borderRadius: '5px 5px 0px 0px',
        backgroundColor: '#635ee7',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: 600,
        fontFamily: 'Epilogue',
        fontSize: theme.typography.pxToRem(16),
        marginRight: theme.spacing(1),
        color: '#1D1E1B',
        '&.Mui-selected': {
            color: '#0275D8',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '&.MuiButtonBase-root': {
            outline: 'none'
        }
    }),
);

function FavoriteIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M14.6875 1.7124C12.3186 1.7124 10.7965 3.26252 10 4.65525C9.20348 3.26252 7.68145 1.7124 5.3125 1.7124C2.30613 1.7124 0 4.01494 0 7.06881C0 10.3802 2.88922 12.6352 7.20363 16.0496C9.31621 17.7199 9.45195 17.8469 10 18.2874C10.4473 17.9279 10.6948 17.7112 12.7964 16.0496C17.1108 12.6352 20 10.3802 20 7.06881C20 4.01498 17.6939 1.7124 14.6875 1.7124Z" fill="#FF5353" />
        </svg>
    )
}

function PaperPlaneIcon() {

    return (
        <svg width="100%" height="100%" viewBox="0 0 123 123" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_2133_1793" maskUnits="userSpaceOnUse" x="0" y="0" width="123" height="123">
                <path d="M122.106 0H0V122.106H122.106V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_2133_1793)">
                <path
                    d="M112.706 18.0762L56.8669 106.863L48.3686 63.126L10.1758 40.1811L112.706 18.0762Z"
                    stroke="#2e55fa"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M48.1074 63.3082L112.706 18.0762"
                    stroke="#2e55fa"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

export default function CandidateProfileNav(props) {

    const [saveLoading, setSaveLoading] = useState(false);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { employerData, savedCandidateIds, settingEmployerData } = useContext(EmployerContext)

    const alertContext = useAlert();

    const unSaveCandidate = () => {
        setSaveLoading(true);
        deleteEmployerSaveCandidateInfo(employerData.id, props.candidateInfo.id)
            .then(response => {
                setSaveLoading(false);
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    settingEmployerData()
                    props.renderState()
                }
            })
    }

    const saveCandidate = () => {
        setSaveLoading(true);
        postEmployerSaveCandidateInfo(employerData.id, props.candidateInfo.id)
            .then(response => {
                setSaveLoading(false);
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    settingEmployerData()
                    props.renderState()
                }
            })
    }

    const [open, setOpen] = useState(false);
    const [onHover, setOnHover] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 1500); // Close the tooltip after 2000 milliseconds (adjust as needed)
    };

    const handleOnHoverTooltipClose = () => {
        setOnHover(false);
    };

    function copyUrl() {
        const currentUrl = window.location.href;

        try {
            navigator.clipboard.writeText(currentUrl).then(() => {
                alertContext.setResponseMessage('URL copied')
                alertContext.setOpenAlert(true)
                // You can setResponseMessage and setOpenAlert here if needed
            }).catch((err) => {
                console.error("Error copying URL to clipboard:", err);
                // Handle the error if necessary
            });
        } catch (err) {
            console.error("Error copying URL to clipboard:", err);
            // Handle the error if necessary
        }
    }

    return (
        <Box className='card shadow-sm' sx={{ position: 'sticky', top: props.stickyTop ? props.stickyTop : { xs: '95px', sm: '95px', md: '147px' }, zIndex: '99', width: '100%' }}>
            <Box sx={{ padding: '10px', display: { xs: 'block', sm: 'block', md: 'none' } }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        props.masking ?
                            <MaskingRoundedImage />
                            :
                            <Box sx={{ width: { xs: '100px', sm: '120px' }, height: { xs: '100px', sm: '120px' } }}>
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <div style={{ position: 'absolute', bottom: '5px', right: '5px' }}>
                                        {props.candidateInfo.is_cxo_eligible == 1 ? <img style={{ width: '30px', height: '30px', boxShadow: '1px 1px 5px 0px #e0f7fd', borderRadius: '50%' }} src='/v2/icons/cxo - verified.svg' /> : ''}
                                    </div>
                                    <img alt={props.candidateInfo?.first_name} src={props.candidateInfo?.profile_pic_base64 ? props.candidateInfo?.profile_pic_base64 : "/images/profile.png"} style={{ height: "100px", width: "100px", borderRadius: '50%', margin: '5px', border: '5px solid #EDEDED', padding: '5px' }} />
                                </div>
                            </Box>
                    }

                    <div style={{ marginLeft: '20px' }}>
                        <div style={{ fontFamily: 'Epilogue', fontWeight: 'bold', fontSize: '18px', color: '#1D1E1B' }}>
                            {props.masking ? <CommonMasking>Abcd Xyz</CommonMasking> : props.candidateInfo?.first_name}
                        </div>
                        <div style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#1D1E1B' }}>
                            {props.masking ? <CommonMasking>Web Developer</CommonMasking> : props.candidateInfo?.profession}
                        </div>
                        {
                            props.userData?.email == props.candidateInfo?.user?.email ? ""
                                :
                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: '10px' }}>
                                    {
                                        savedCandidateIds?.includes(props.candidateInfo?.id) ?
                                            <LoadingButton
                                                loading={saveLoading}
                                                size='small'
                                                variant='outlined'
                                                startIcon={<FavoriteIcon />}
                                                onClick={unSaveCandidate}
                                                sx={{
                                                    flexWrap: 'nowrap',
                                                    fontSize: '12px',
                                                    textTransform: 'capitalize',
                                                    height: '37px'
                                                }}
                                            >
                                                Saved
                                            </LoadingButton>
                                            :
                                            <LoadingButton
                                                loading={saveLoading}
                                                size='small'
                                                variant='outlined'
                                                startIcon={<FavoriteBorderIcon />}
                                                onClick={saveCandidate}
                                                sx={{
                                                    flexWrap: 'nowrap',
                                                    fontSize: '12px',
                                                    textTransform: 'capitalize',
                                                    height: '37px'
                                                }}
                                            >
                                                Save
                                            </LoadingButton>
                                    }
                                    <div style={{ marginTop: '5px', marginLeft: '10px' }}>
                                        <ClickAwayListener onClickAway={handleTooltipClose}>
                                            <LightTooltip
                                                onClose={handleTooltipClose}
                                                open={open}
                                                title={onHover ? "Copy URL" : "Copied"}
                                                onMouseEnter={() => [setOnHover(true), handleTooltipOpen()]}
                                            >
                                                <LinkIcon
                                                    sx={{
                                                        background: '#178bf4',
                                                        color: 'white',
                                                        padding: 1,
                                                        width: '35px',
                                                        height: '35px',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => [copyUrl(), handleTooltipOpen(), handleOnHoverTooltipClose()]}
                                                />
                                            </LightTooltip>
                                        </ClickAwayListener>
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        {
                                            props.masking ?
                                                <CommonMasking>
                                                    <div style={{ width: '40px', height: '40px' }}>
                                                        <PaperPlaneIcon />
                                                    </div>
                                                </CommonMasking>
                                                :
                                                <RouterLink to={`/messages/${props.candidateInfo.user_id}`}>
                                                    <div style={{ width: '40px', height: '40px' }}>
                                                        <PaperPlaneIcon />
                                                    </div>
                                                </RouterLink>
                                        }

                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ fontFamily: 'Epilogue' }}
                    allowScrollButtonsMobile
                >
                    {/* <StyledTab label={<Link onClick={() => setValue(0)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='basic_info_block'>Basic Info</Link>} /> */}
                    <StyledTab label={<Link onClick={() => setValue(0)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='about_box'>Profile Summary</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(1)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='skill_box'>Key Skills</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(2)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='employment_box'>Employment</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(3)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='education_box'>Education</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(4)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='resume_box'>Resume</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(5)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='project_box'>Project</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(6)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='certificate_box'>Certificate</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(7)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='personal_box'>Personal Info</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(8)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='candidate_profile_view_career_box'>Career Info</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(9)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='candidate_profile_view_language_box'>Language</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(10)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='candidate_profile_view_hobby_box'>Hobby</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(11)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='candidate_profile_view_reference_box'>Reference</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(12)} smooth={true} offset={props.tabScrollOffsetLgDevice ? props.tabScrollOffsetLgDevice : -200} duration={500} to='social_box'>Social Profile</Link>} />
                </StyledTabs>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ fontFamily: 'Epilogue' }}
                    allowScrollButtonsMobile
                >
                    <StyledTab label={<Link onClick={() => setValue(0)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='basic_info_block'>Basic Info</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(1)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='about_box'>Profile Summary</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(2)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='skill_box'>Key Skills</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(3)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='employment_box'>Employment</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(4)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='education_box'>Education</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(5)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='resume_box'>Resume</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(6)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='project_box'>Project</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(7)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='certificate_box'>Certificate</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(8)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='personal_box'>Personal Info</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(9)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='candidate_profile_view_career_box'>Career Info</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(10)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='candidate_profile_view_language_box'>Language</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(11)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='candidate_profile_view_hobby_box'>Hobby</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(12)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='candidate_profile_view_reference_box'>Reference</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(13)} smooth={true} offset={props.tabScrollOffsetSmDevice ? props.tabScrollOffsetSmDevice : -280} duration={500} to='social_box'>Social Profile</Link>} />

                </StyledTabs>
            </Box>

        </Box>
    )
}