import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Box, Button, CardActions, Divider, Grid, Stack, Tooltip, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import EmployerProfilePicture from "../EmployerComponents/EmployerProfilePicture";
import moment from "moment";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import WorkHistoryTwoToneIcon from '@mui/icons-material/WorkHistoryTwoTone';
import { createTheme } from '@mui/material/styles';

function WorkHistoryIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6510_2644)">
        <path d="M0 9.15274C0.0655582 8.70586 0.118479 8.25741 0.218002 7.81606C0.607547 6.05713 1.47167 4.43822 2.71672 3.13475C4.07527 1.70026 5.70909 0.711856 7.63792 0.285099C11.2578 -0.515958 14.4351 0.369098 17.0886 2.96514C18.7078 4.55148 19.6371 6.50699 19.921 8.75871C20.5596 13.8214 17.2675 18.6088 12.2811 19.716C8.3954 20.5785 5.07247 19.4659 2.40788 16.5074C1.03787 14.9861 0.291851 13.1627 0.038307 11.1366C0.0260642 11.0396 0.0422586 10.9394 0.00118594 10.8456L0 9.15274ZM18.4037 10.36C18.6332 5.72526 14.9753 1.65648 10.1272 1.57602C5.48675 1.49911 1.60617 5.15375 1.5572 9.92735C1.51179 14.5164 5.1475 18.3931 9.96919 18.416C14.6649 18.4384 18.2536 14.6982 18.4041 10.36H18.4037Z" fill="#0079C1" />
        <path d="M12.6886 10.7769C11.8087 10.7769 10.9287 10.7769 10.0488 10.7769C9.54689 10.7769 9.25385 10.5114 9.20172 10.0133C9.19106 9.90913 9.18789 9.80382 9.1875 9.69772C9.1875 8.1106 9.1875 6.5236 9.1875 4.93674C9.1875 4.80619 9.1958 4.67485 9.20883 4.54469C9.24832 4.12898 9.60731 3.81108 10.0074 3.82883C10.2107 3.83425 10.404 3.91836 10.5464 4.0634C10.6889 4.20845 10.7693 4.40307 10.7708 4.60622C10.7779 4.96672 10.7731 5.32762 10.7731 5.68811V8.95386C10.7731 9.21575 10.7731 9.21614 11.0299 9.21614C12.4745 9.21614 13.9192 9.21417 15.3634 9.21614C15.9017 9.21614 16.265 9.69615 16.1276 10.2085C16.0848 10.3717 15.9887 10.5159 15.8546 10.6185C15.7205 10.7211 15.556 10.7761 15.3871 10.7749C14.4874 10.7784 13.5878 10.7765 12.6886 10.7769Z" fill="#0079C1" />
      </g>
      <defs>
        <clipPath id="clip0_6510_2644">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}

/**
 * デフォルトのスライダーのパラメーター
 */
export const DefaultParams = {
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 0,
  loop: true,
  navigation: true,
  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
  speed: 1000,
  centeredSlides: false,
  virtual: true,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 3,
    },
    3000: {
      slidesPerView: 4,
    },
  },
  // テストように使ったのでコメントアウト
  //pagination: {
  //  clickable: true,
  //},
  pagination: {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="custom_pagination_bullet ' + className + '">' + '</span>';
    },
  },
};

const dayLeft = (date) => {
  const today = moment(new Date(), 'YYYY-MM-DD').startOf('day'); 
  const targetDate = moment(date, 'YYYY-MM-DD').startOf('day');
  
  let days = targetDate.diff(today, 'days');	  
  return days >= 0 ? days : null;
};


const displaySalary = (item) => {
  if (item.negotiable) {
    return 'Negotiable'
  } else {
    if (item.maximum_salary) {
      return item.minimum_salary + ' - ' + item.maximum_salary +
        ` ${item.salary_currency ? item.salary_currency?.name : 'Taka'} /` + item.salary_type.type
    }
  }
}

const displaySalaryHjob = (item) => {
  if (item.negotiable) {
    return 'Negotiable'
  } else {
    if (item.maximum_salary) {
      return item.minimum_salary + ' - ' + item.maximum_salary + ` ${item.salary_currency ? item.salary_currency?.name : 'Taka'} /` + item.salary_type.type || item.salary_type
    }
  }
}

/**
 * 今月の最近の仕事
 * @param {Array} jobs - 求人情報の配列
 * @returns {Object} スライダーのスライドとパラメーター
 */
export const GetMostRecentJobsSliderProps = ({ jobs }) => {
  const slides =
    jobs?.map((item, index) => {
      return (
        <Card
          key={index}
          className="atb-card"
          style={{ display: "flex", height: "100%", margin: "1rem" }}
        >
          <Grid
            container
            spacing={2}
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Grid item>
              <Tooltip title="Go to Profile">
                <Link
                  to={`/employer/profile/${item?.author_id}`}
                  className="text-dark"
                >
                  <Typography variant="h6" align="left">
                    {" "}
                    {item?.employer?.employer_name}
                  </Typography>
                </Link>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Go to Profile">
                <Avatar alt="Employer Picture" sx={{ width: 60, height: 60 }}>
                  <Link to={`/employer/profile/${item?.author_id}`}>
                    <EmployerProfilePicture
                      profile_picture={item?.employer?.profile_pic_base64}
                      height={60}
                      width={60}
                    />
                  </Link>
                </Avatar>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <Typography
                className="text-left text-truncate"
                style={{ maxWidth: "13rem" }}
              >
                {item?.job_title}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="subheading" align="left" sx={{ color: '#1D1E1B' }}>
                {displaySalary(item)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1} alignItems={"center"}>
            <Grid item>
              <Link to={`/jobs/${item?.post_id}`}>
                <Button variant="contained">Job Details</Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to={"/search/jobs"}>
                <Button variant="outlined">See More</Button>
              </Link>
            </Grid>
            <Grid item>
              <Typography variant="caption" align="right">
                {moment(item?.post_date)
                  .startOf("day")
                  .fromNow()}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      );
    }) ?? null;

  return {
    slides,
    params: DefaultParams,
  };
};

GetMostRecentJobsSliderProps.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      author_id: PropTypes.number,
      job_title: PropTypes.string,
      minimum_salary: PropTypes.number,
      maximum_salary: PropTypes.number,
      post_date: PropTypes.string,
      employer: PropTypes.shape({
        employer_name: PropTypes.string,
        profile_pic_base64: PropTypes.string,
      }),
      salary_currency: PropTypes.shape({
        name: PropTypes.string,
      }),
      salary_type: PropTypes.shape({
        type: PropTypes.string,
      }),
    })
  ),
};

/**
 * Live Jobs currently
 * @param {Array} jobs - 求人情報の配列
 * @returns {Object} スライダーのスライドとパラメーター
 */
export const GetLiveJobsSliderProps = ({ jobs, t }) => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        lg: 1200,
        xl: 1536,
        customBreakpoint1: 768,
        customBreakpoint2: 992,
        customBreakpoint3: 1024,
        customBreakpoint4: 1200,
      },
    },
  });

  let itemCountInSingleColumn = 1
  let liveJobsA = [];
  for (let i = 0, k = 0; i < jobs?.length; i = i + itemCountInSingleColumn, k++) {
    liveJobsA[k] = [];
    for (let j = 0; j < itemCountInSingleColumn; j++) {
      if (jobs[i + j])
        liveJobsA[k][j] = jobs[i + j];
    }
  }

  const slides =
    liveJobsA?.map((litem, lindex) => {
      return (
        <div key={[litem, lindex]}>
          {
            litem?.map((item, index) => {
              return (
                <Card
                  key={index}
                  style={{ display: "flex", flexDirection: 'column', margin: "20px 10px", fontFamily: 'Epilogue,sans-serif', }}
                >
                  <CardContent>
                    <Stack spacing={2}>
                      <Box sx={{ flexGrow: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid item>
                          {/* Job Information */}
                          <Tooltip title="Go to Job">
                            <Link
                              to={`/jobs/${item?.post_id}`}
                            >
                              <Typography
                                className="text-left text-truncate"
                                variant="h6"
                                align="left"
                                sx={{
                                  [theme.breakpoints.up('customBreakpoint1')]: { maxWidth: "285px" },
                                  [theme.breakpoints.up('customBreakpoint2')]: { maxWidth: "400px" },
                                  [theme.breakpoints.up('customBreakpoint3')]: { maxWidth: "400px" },
                                  maxWidth: {
                                    xs: "270px",
                                    sm: "450px",
                                  },
                                  fontSize: '18px',
                                  color: '#0079C1',
                                  fontWeight: '700',
                                  '@media (min-width: 1200px)': {
                                    maxWidth: "330px",
                                  },
                                  '@media (min-width: 1536px)': {
                                    maxWidth: "430px",
                                  },
                                }}>
                                {item?.job_title}
                              </Typography>
                            </Link>
                          </Tooltip>
                        </Grid>
                      </Box>
                      <Box sx={{ flexGrow: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid item>
                          <Stack spacing={1}>

                            {/* Profile Information */}
                            <Typography
                              className="text-left text-truncate"
                              sx={{
                                maxWidth: {
                                  xs: "200px",
                                  sm: "350px",
                                  lg: "200px",
                                },
                                [theme.breakpoints.up('customBreakpoint1')]: { maxWidth: "220px" },
                                [theme.breakpoints.up('customBreakpoint2')]: { maxWidth: "300px" },
                                [theme.breakpoints.up('customBreakpoint3')]: { maxWidth: "180px" },
                                '@media (min-width: 1200px)': {
                                  maxWidth: "250px",
                                },
                                '@media (min-width: 1536px)': {
                                  maxWidth: "350px",
                                },
                                fontSize: '16px', color: '#72737C', fontWeight: '600'
                              }}
                            >
                              {item?.employer?.employer_name}
                            </Typography>

                            {/* Salary Information */}
                            <Typography variant="subheading" align="left" sx={{ fontSize: '14px', color: '#424447', fontWeight: '600', [theme.breakpoints.up('customBreakpoint1')]: { height: "42px" }, [theme.breakpoints.up('customBreakpoint4')]: { height: "21px !important" }, }}>
                              Salary: {displaySalary(item)}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Go to Profile">
                            <Avatar alt="Employer Picture" sx={{ width: 60, borderRadius: '5px', height: 'unset' }}>
                              <Link to={`/employer/profile/${item?.employer?.slug}`}>
                                <EmployerProfilePicture
                                  profile_picture={item?.employer?.profile_pic_base64}
                                  height={60}
                                  width={60}
                                />
                              </Link>
                            </Avatar>
                          </Tooltip>
                        </Grid>
                      </Box>
                    </Stack>

                  </CardContent>
                  <Divider sx={{ marginTop: 2 }} />
                  <CardActions sx={{ p: {xs: 1, md: 2} }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        // flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", width: { xs: "calc(100% - 100px)", lg: "calc(100% - 112px)" } }}>
                        <Box
                          sx={{
                            maxWidth: "100%",
                            padding: "10px",
                            backgroundColor: "#E9F5FF",
                            borderRadius: "50%",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <WorkHistoryIcon />
                        </Box>
                        <Box
                          className="text-body"
                          sx={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            color: "#1D1E1B",
                            fontWeight: "600",
                            fontFamily: "Epilogue,sans-serif",
                          }}
                        >
                          <span>Deadline: </span>
                          {(item?.job_post?.job_deadline || item?.job_deadline) ?
                          <span>{moment(item?.job_post?.job_deadline ? item?.job_post?.job_deadline : item?.job_deadline).format("MMM Do, YY")}</span>
                          :
                          "N/A"
                          }
                          &nbsp;
                          <span style={{ color: "#DB1616" }}>
                          {
                            (() => {
                              const daysLeft = dayLeft(item?.job_post?.job_deadline || item?.job_deadline);
                              return daysLeft === 0 ? `(Expiring Today)` : daysLeft === 1 ? `(${daysLeft} day left)` : daysLeft > 1 && daysLeft < 8 ? `(${daysLeft} days left)` : ``; 
                            })()
                          }
                          </span>
                        </Box>
                      </Box>
                      <Box>
                        <Stack
                          spacing={1}
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Link to={`/jobs/${item?.post_id}`}>
                            <Button
                              className="job_details_btn_click"
                              variant="contained"
                              sx={{
                                fontSize: { xs: "10px", md: "12px", lg: "14px" },
                                marginY: 1,
                                // marginLeft: 1,
                                fontFamily: "Epilogue,sans-serif",
                                fontWeight: "700",
                                textTransform: "capitalize",
                                padding: "6px 10px"
                              }}
                            >
                              View Details
                            </Button>
                          </Link>
                        </Stack>
                      </Box>
                    </Box>
                  </CardActions>
                </Card>
              )
            })
          }
        </div>
      )
    }) ?? null;

  return {
    slides,
    params: DefaultParams,
  };
};

GetLiveJobsSliderProps.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      author_id: PropTypes.number,
      job_title: PropTypes.string,
      minimum_salary: PropTypes.number,
      maximum_salary: PropTypes.number,
      post_date: PropTypes.string,
      employer: PropTypes.shape({
        employer_name: PropTypes.string,
        profile_pic_base64: PropTypes.string,
      }),
      salary_currency: PropTypes.shape({
        name: PropTypes.string,
      }),
      salary_type: PropTypes.shape({
        type: PropTypes.string,
      }),
    })
  ),
};

/**
 * Expire Soon Jobs currently
 * @param {Array} jobs - 求人情報の配列
 * @returns {Object} スライダーのスライドとパラメーター
 */
export const GetExpireSoonJobsSliderProps = ({ jobs, t }) => {

  let itemCountInSingleColumn = 1
  let expireSoonJobsA = [];
  for (let i = 0, k = 0; i < jobs?.length; i = i + itemCountInSingleColumn, k++) {
    expireSoonJobsA[k] = [];
    for (let j = 0; j < itemCountInSingleColumn; j++) {
      if (jobs[i + j])
        expireSoonJobsA[k][j] = jobs[i + j];
    }
  }

  const slides = expireSoonJobsA?.map((eitem, eindex) => {
    return (
      <div key={eindex}>
        {
          eitem.map((item, index) => {
            return (
              <Card
                key={index}
                style={{ display: "flex", flexDirection: 'column', margin: "20px 10px", fontFamily: 'Epilogue,sans-serif', }}
              >
                <CardContent>
                  <Stack spacing={2}>
                    <Box sx={{ flexGrow: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Grid item>
                        {/* Job Information */}
                        <Tooltip title="Go to Job">
                          <Link
                            to={`/jobs/${item?.post_id}`}
                          >
                            <Typography
                              className="text-left text-truncate"
                              variant="h6"
                              align="left"
                              sx={{
                                maxWidth: "26rem",
                                fontSize: '18px',
                                color: '#0079C1',
                                fontWeight: '700',
                                wordBreak: 'break-word',
                                '@media (max-width: 1200px)': {
                                  maxWidth: "16rem",
                                },
                                '@media (max-width: 600px)': {
                                  fontSize: '14px',
                                  maxWidth: "16rem",
                                }
                              }}>
                              {item?.job_title}
                            </Typography>
                          </Link>
                        </Tooltip>
                      </Grid>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Grid item>
                        <Stack spacing={1}>

                          {/* Profile Information */}
                          <Typography
                            className="text-left text-truncate"
                            sx={{ maxWidth: "13rem", fontSize: '16px', color: '#72737C', fontWeight: '600' }}
                          >
                            {item?.employer?.employer_name}
                          </Typography>

                          {/* Salary Information */}
                          <Typography variant="subheading" align="left" sx={{ fontSize: '14px', color: '#424447', fontWeight: '600' }}>
                            Salary: {displaySalary(item)}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Go to Profile">
                          <Avatar alt="Employer Picture" sx={{ width: 60, borderRadius: '5px', height: 'unset' }}>
                            <Link to={`/employer/profile/${item?.employer?.slug}`}>
                              <EmployerProfilePicture
                                profile_picture={item?.employer?.profile_pic_base64}
                                height={60}
                                width={60}
                              />
                            </Link>
                          </Avatar>
                        </Tooltip>
                      </Grid>
                    </Box>
                  </Stack>

                </CardContent>
                <Divider sx={{ marginTop: 2 }} />
                <CardActions sx={{ p: {xs: "4px", md: 2} }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        // flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", width: { xs: "calc(100% - 100px)", lg: "calc(100% - 112px)" } }}>
                        <Box
                          sx={{
                            maxWidth: "100%",
                            padding: "10px",
                            backgroundColor: "#E9F5FF",
                            borderRadius: "50%",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <WorkHistoryIcon />
                        </Box>
                        <Box
                          className="text-body"
                          sx={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            color: "#1D1E1B",
                            fontWeight: "600",
                            fontFamily: "Epilogue,sans-serif",
                          }}
                        >
                          <span>Deadline: </span>
                          {(item?.job_post?.job_deadline || item?.job_deadline) ? 
                            <span>{moment(item?.job_post?.job_deadline ? item?.job_post?.job_deadline : item?.job_deadline).format("MMM Do, YY")}</span>
                            :
                            "N/A"
                          }
                          &nbsp;
                          <span style={{ color: "#DB1616" }}>
                          {
                            (() => {
                              const daysLeft = dayLeft(item?.job_post?.job_deadline || item?.job_deadline);
                              return daysLeft === 0 ? `(Expiring Today)` : daysLeft === 1 ? `(${daysLeft} day left)` : daysLeft > 1 && daysLeft < 8 ? `(${daysLeft} days left)` : ``; 
                            })()
                          }
                          </span>
                        </Box>
                      </Box>
                      <Box>
                        <Stack
                          spacing={1}
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Link to={`/jobs/${item?.post_id}`}>
                            <Button
                              className="job_details_btn_click"
                              variant="contained"
                              sx={{
                                fontSize: { xs: "10px", md: "12px", lg: "14px" },
                                marginY: 1,
                                // marginLeft: 1,
                                fontFamily: "Epilogue,sans-serif",
                                fontWeight: "700",
                                textTransform: "capitalize",
                                padding: "6px 10px"
                              }}
                            >
                              View Details
                            </Button>
                          </Link>
                        </Stack>
                      </Box>
                    </Box>
                </CardActions>
              </Card>
            )
          })
        }
      </div>
    )
  }) ?? null;

  return {
    slides,
    params: DefaultParams,
  };
};

GetExpireSoonJobsSliderProps.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      author_id: PropTypes.number,
      job_title: PropTypes.string,
      minimum_salary: PropTypes.number,
      maximum_salary: PropTypes.number,
      post_date: PropTypes.string,
      employer: PropTypes.shape({
        employer_name: PropTypes.string,
        profile_pic_base64: PropTypes.string,
      }),
      salary_currency: PropTypes.shape({
        name: PropTypes.string,
      }),
      salary_type: PropTypes.shape({
        type: PropTypes.string,
      }),
    })
  ),
};

/**
 * 高給の仕事
 * @param {Array} applicants - 求職者情報の配列
 * @returns {Object} スライダーのスライドとパラメーター
 */
export const GetHighPaidJobsSliderProps = ({ jobs, t }) => {

  let itemCountInSingleColumn = 1
  let highPaidJobs = [];
  for (let i = 0, k = 0; i < jobs?.length; i = i + itemCountInSingleColumn, k++) {
    highPaidJobs[k] = [];
    for (let j = 0; j < itemCountInSingleColumn; j++) {
      if (jobs[i + j])
        highPaidJobs[k][j] = jobs[i + j];
    }
  }

  const slides = highPaidJobs?.map((hitem, hindex) => {
    return (
      <div key={[hitem, hindex]}>
        {
          hitem.map((item, index) => {
            return (

              <Card
                key={index}
                style={{ display: "flex", flexDirection: 'column', margin: "20px 10px", fontFamily: 'Epilogue,sans-serif', }}
              >
                <CardContent>
                  <Stack spacing={2}>
                    <Box sx={{ flexGrow: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Grid item>
                        {/* Job Information */}
                        <Tooltip title="Go to Job">
                          <Link
                            to={`/jobs/${item?.job_post_id}`}
                          >
                            <Typography
                              className="text-left text-truncate"
                              variant="h6"
                              align="left"
                              sx={{
                                maxWidth: "26rem",
                                fontSize: '18px',
                                color: '#0079C1',
                                fontWeight: '700',
                                wordBreak: 'break-word',
                                '@media (max-width: 1200px)': {
                                  maxWidth: "16rem",
                                },
                                '@media (max-width: 600px)': {
                                  fontSize: '14px',
                                  maxWidth: "16rem",
                                }
                              }}>
                              {item?.job_title}
                            </Typography>
                          </Link>
                        </Tooltip>
                      </Grid>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Grid item>
                        <Stack spacing={1}>

                          {/* Profile Information */}
                          <Typography
                            className="text-left text-truncate"
                            sx={{ maxWidth: "13rem", fontSize: '16px', color: '#72737C', fontWeight: '600' }}
                          >
                            {item?.employer_name}
                          </Typography>

                          {/* Salary Information */}
                          <Typography variant="subheading" align="left" sx={{ fontSize: '14px', color: '#424447', fontWeight: '600' }}>
                            Salary: {displaySalaryHjob(item)}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Go to Profile">
                          <Avatar alt="Employer Picture" sx={{ width: 60, borderRadius: '5px', height: 'unset' }}>
                            <Link to={`/employer/profile/${item?.employer?.slug}`}>
                              <EmployerProfilePicture
                                profile_picture={item?.employer_image}
                                height={60}
                                width={60}
                              />
                            </Link>
                          </Avatar>
                        </Tooltip>
                      </Grid>
                    </Box>
                  </Stack>

                </CardContent>
                <Divider sx={{ marginTop: 2 }} />
                <CardActions>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{
                        width: '42px',
                        height: '42px',
                        backgroundColor: '#E9F5FF',
                        borderRadius: '50%',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                      >
                        <WorkHistoryIcon sx={{ color: 'black', position: 'absolute', top: '8px', left: '10px' }} />
                      </Box>
                      <Box className='text-body' sx={{ marginLeft: '10px', fontSize: '14px', color: '#424447', fontWeight: '600' }}>
                        <span>Deadline: </span>
                        <br />
                        <span >
                          {moment(item?.job_deadline).format("MMM Do YY")}
                        </span>
                        &nbsp;
                        <span style={{ color: '#DB1616' }}>
                          ({dayLeft(item?.job_deadline) + ' days left'})
                        </span>
                      </Box>
                    </Box>
                    <Box>
                      <Link to={`/cxo-jobs/${item?.post_id}`}>
                        <Button variant="contained" sx={{ fontSize: { md: '12px', lg: '14px' }, margin: 1, fontFamily: 'Epilogue,sans-serif', fontWeight: '600', textTransform: 'capitalize' }}>{t("button:view-details")}</Button>
                      </Link>
                    </Box>
                  </Box>
                </CardActions>
              </Card>
            )
          })
        }
      </div>
    )
  }) ?? null;

  return {
    slides,
    params: DefaultParams,
  };
};

GetHighPaidJobsSliderProps.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      employer_id: PropTypes.number,
      job_title: PropTypes.string,
      minimum_salary: PropTypes.number,
      maximum_salary: PropTypes.number,
      job_post_date: PropTypes.string,
      employer_name: PropTypes.string,
      employer_image: PropTypes.string,
      salary_currency_name: PropTypes.string,
      salary_type: PropTypes.string,
      job_post_id: PropTypes.number,
    })
  ),
};

/**
 * 特典から探す
 * @param {Array} applicants - 求職者情報の配列
 * @returns {Object} スライダーのスライドとパラメーター
 */
export const GetBenefitSliderProps = ({ jobs, setCheckedJobBenefitList }) => {

  let itemCountInSingleColumn = 4
  let benifitA = [];
  for (let i = 0, k = 0; i < jobs.length; i = i + itemCountInSingleColumn, k++) {
    benifitA[k] = [];
    for (let j = 0; j < itemCountInSingleColumn; j++) {
      if (jobs[i + j])
        benifitA[k][j] = jobs[i + j];
    }
  }

  const slides = benifitA?.map((bitem, bindex) => {
    return (
      <div key={[bitem, bindex]}>
        {
          bitem?.map((item, index) => {
            return (
              <Card key={[item, index]} style={{ margin: '15px' }}>
                <Link
                  style={{ display: 'flex' }}
                  className="text-dark"
                  to={{
                    pathname: '/search/jobs',
                    state: { fromHome: true }
                  }}
                  onClick={() => {
                    if (setCheckedJobBenefitList) {
                      setCheckedJobBenefitList([item.benefit_option_id]);
                    }
                  }}
                >
                  <CardMedia sx={{ width: 70, backgroundColor: '#EBEBEB', textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <img src={item.benefit_option?.category?.category_icon_s3 || ''} alt="icon" />
                    </div>
                  </CardMedia>
                  <CardContent sx={{ width: 'calc(100% - 70px)', padding: '8px !important' }}>
                    <div className="d-flex">
                      <div style={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: '11rem' }}>
                        <Typography noWrap color="#0079C1" mb={1} sx={{ fontWeight: 'bold', }}>
                          {item.title}
                        </Typography>
                      </div>
                      <Typography sx={{ color: '#DB1616', fontWeight: 'bold' }} mb={1}>({item.total_job})</Typography>
                    </div>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: '12rem' }}>
                      <Typography noWrap sx={{ fontFamily: 'Epilogue,sans-serif', fontSize: '12px', color: '#72737C' }}>
                        {item.benefit_option?.category?.title}
                      </Typography>
                    </div>
                  </CardContent>
                </Link>
              </Card>
            )
          })

        }
      </div>
    )
  }) ?? null;

  return {
    slides,
    params: DefaultParams,
  };
};

GetBenefitSliderProps.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      benefit_option_id: PropTypes.number,
      title: PropTypes.string,
      total_job: PropTypes.number,
      image: PropTypes.string,
    })
  ),
  setCheckedJobBenefitList: PropTypes.func,
};
