import React, { useState, useContext, useEffect } from 'react'
import { updateCandidateCareerInfo } from '../../../../../fetchAPI';
import Select from 'react-select'
import CandidateUpdateModal from './component/CandidateUpdateModal';
import EditSVG from './component/EditSVG';
import { useAlert } from '../../../../context/AlertContext';
import { DropdownContext } from '../../../../context/DropdownContext';
import SingleCareerInfoBlock from './component/SingleCareerInfoBlock';
import { Box, CircularProgress, Grid, InputLabel, Typography, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom'
import { CandidateContext } from '../../../../context/CandidateContext';

function UpdateCareerInfo() {
    const { candidateData, settingCandidateData, candLoader } = useContext(CandidateContext)
    const { district, departments, industries, levels, qualifications } = useContext(DropdownContext);

    const [careerData, setCareerData] = useState({})
    const [loader, setLoader] = useState(false)
    const [addModalOpen, setAddModalOpen] = useState(false);

    const alertContext = useAlert();
    const { search } = useLocation();

    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [menuOpen, setMenuOpen] = useState(false);

	useEffect(() => {
	  const scrollToHash = () => {
		const params = new URLSearchParams(search);
		const scrollTo = params.get('scrollTo');
		if (scrollTo) {
		  setTimeout(() => {
			const element = document.getElementById(scrollTo);
			if (element) {
				const offset = element.offsetTop - 220;
				window.scrollTo({ top: offset, behavior: 'smooth' });
			}
		  }, 100);
		}
	  };
  
	  scrollToHash();
	  window.addEventListener('popstate', scrollToHash);

	  return () => {
		window.removeEventListener('popstate', scrollToHash);
	  };
	}, [search]);


    const handleChangeSelectMultiValue = (name) => (event) => {
        setCareerData({ ...careerData, [name]: event })
    }

    const handleChangeSelect = (name) => (event) => {
        setCareerData({ ...careerData, [name]: event.value, [name + 'Name']: event.label })
    }

    const updateInfo = (body) => {
        let requestBody = {
            qualification: body.qualification,
            districtIds: body.locations.map((item) => item.value).join(),
            departmentIds: body.departments.map((item) => item.value).join(),
            industryIds: body.industries.map((item) => item.value).join(),
            searching_for_job_status: body.searching_for_job_status,
        }

        setAddModalOpen(false);
        setCareerData({});
        updateCandidateCareerInfo(requestBody).then((response) => {
            if (response && response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                settingCandidateData()
                setLoader(false);
            }
            else {
                console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
                setLoader(false);
            }
        })
    }

    const options = [
        { value: 'Actively', label: 'Actively' },
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
    ];

    return (
        <Box id='update_candidate_career_box'>
            <Box className='card shadow-sm'>
                <Box sx={{ padding: "10px 20px", borderBottom: "1px solid rgb(204, 204, 204)" }}>
                    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Typography sx={{fontSize: "18px", fontWeight: 700, color: "#424447"}}>Career & Application Info</Typography>
                        <Box
                            sx={{ display: {xs: "none", sm: "none", md: "flex !important"} , justifyContent: "space-around", alignItems: "center", color: "#0275D8", fontFamily: "Epilogue", fontWeight: "600 !important", fontSize: "16px !important", backgroundColor: "#E5F5FF", padding: "2px 20px !important", cursor: "pointer" }}
                            onClick={() => {
                                setCareerData({
                                    qualification: candidateData.qualification?.id,
                                    qualificationName: candidateData.qualification?.name,
                                    locations: candidateData.preferred_districts.map((item) => { return { label: item.name, value: item.id } }),
                                    departments: candidateData.preferred_jobcategories.map((item) => { return { label: item.name, value: item.id } }),
                                    industries: candidateData.preferred_organization_types.map((item) => { return { label: item.name, value: item.id } }),
                                    searching_for_job_status: candidateData.searching_for_job_status,
                                });
                                setAddModalOpen(true)
                            }}
                        >
                            <Box sx={{marginRight: "10px"}}>Edit</Box>
                            <EditSVG />
                        </Box>
                        <Box 
                            sx={{display: {xs: "block", sm: "block", md: "none"}, cursor: "pointer"}}
                            onClick={() => {
                                setCareerData({
                                    qualification: candidateData.qualification?.id,
                                    qualificationName: candidateData.qualification?.name,
                                    locations: candidateData.preferred_districts.map((item) => { return { label: item.name, value: item.id } }),
                                    departments: candidateData.preferred_jobcategories.map((item) => { return { label: item.name, value: item.id } }),
                                    industries: candidateData.preferred_organization_types.map((item) => { return { label: item.name, value: item.id } }),
                                    searching_for_job_status: candidateData.searching_for_job_status,
                                });
                                setAddModalOpen(true)
                            }}
                        >
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#E5F5FF"/>
                            <g clip-path="url(#clip0_7080_5348)">
                            <path d="M23.2599 10.54C23.2656 11.0891 23.0546 11.6195 22.6703 12.022C22.6192 12.0769 22.5645 12.1291 22.5105 12.1816C19.2059 15.3947 15.9018 18.6082 12.5981 21.8218C12.4637 21.9566 12.2922 22.0511 12.1041 22.0939C11.1063 22.3299 10.1109 22.5742 9.11442 22.8178C8.59044 22.945 8.2824 22.6462 8.4126 22.1392C8.66595 21.1539 8.91847 20.1683 9.17017 19.1826C9.20408 19.0376 9.27957 18.905 9.38788 18.8001C12.7261 15.556 16.0636 12.311 19.4004 9.0651C20.5017 7.99541 22.2529 8.21429 22.9868 9.50595C23.1658 9.82281 23.2598 10.1784 23.2599 10.54ZM9.59113 21.675C10.3491 21.4901 11.0713 21.3131 11.7943 21.1384C11.8505 21.1221 11.9008 21.0907 11.9394 21.0479C15.2479 17.8294 18.5569 14.6105 21.8662 11.3911C22.3535 10.9167 22.3552 10.1883 21.8852 9.72757C21.4103 9.26168 20.6538 9.26203 20.1615 9.74027C16.8548 12.9525 13.5488 16.1662 10.2436 19.3812C10.2026 19.4214 10.161 19.4584 10.1455 19.5185C9.96409 20.2269 9.78096 20.9343 9.59113 21.675Z" fill="#0079C1"/>
                            <path d="M19.8039 22.8609C18.6169 22.8609 17.4301 22.8609 16.2436 22.8609C15.9066 22.8609 15.685 22.6894 15.6592 22.4122C15.6453 22.2887 15.6801 22.1646 15.7566 22.0651C15.8331 21.9656 15.9456 21.898 16.0714 21.876C16.1407 21.864 16.211 21.8585 16.2813 21.8595C18.6372 21.8595 20.9932 21.8595 23.3493 21.8595C23.7043 21.8595 23.9114 22.0029 23.9615 22.2784C23.9741 22.3432 23.9729 22.4097 23.9582 22.4741C23.9435 22.5384 23.9155 22.5992 23.8759 22.6527C23.8363 22.7062 23.786 22.7513 23.7279 22.7854C23.6698 22.8194 23.6053 22.8416 23.5381 22.8506C23.4623 22.8593 23.3861 22.8626 23.3098 22.8606C22.1419 22.861 20.9732 22.8611 19.8039 22.8609Z" fill="#0079C1"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_7080_5348">
                            <rect width="15.5897" height="14.4762" fill="white" transform="translate(8.38281 8.38281)"/>
                            </clipPath>
                            </defs>
                            </svg>
                        </Box>
                    </Box>
                </Box>
                <Box className='card-body'>
                    {
                        candLoader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            <>
                                <SingleCareerInfoBlock title="Qualification" value={candidateData.qualification?.name} />
                                <SingleCareerInfoBlock title="Preferred Job Category" value={candidateData.preferred_jobcategories?.map((item) => item.name).join(' , ')} />
                                <SingleCareerInfoBlock title="Preferred Organization Types" value={candidateData.preferred_organization_types?.map((item) => item.name).join(' , ')} />
                                <SingleCareerInfoBlock title="Preferred Locations" value={candidateData.preferred_districts?.map((item) => item.name).join(' , ')} />
                                <SingleCareerInfoBlock title="Looking For Job Status" value={candidateData.searching_for_job_status} />
                            </>
                    }
                </Box>
            </Box>

            <CandidateUpdateModal form="candidateCareerInfoUpdateForm" open={addModalOpen} onClose={() => [setAddModalOpen(false), setCareerData({})]} title='Career & Application Info'>
                <Box className="card-body">
                    <form id='candidateCareerInfoUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateInfo(careerData); }}>
                        <Grid container spacing={3} rowSpacing={1}>
                            <Grid item xs={12} md={6} sx={{zIndex: 1000, marginBottom: "12px"}}>
                                <InputLabel>Qualification</InputLabel>
                                <Select
                                    placeholder='Add Qualification'
                                    options={qualifications?.map((item) => {
                                        return (
                                            { label: item.name, value: item.id }
                                        )
                                    })}
                                    value={careerData.qualification ? {label: careerData.qualificationName, value: careerData.qualification} : null}
                                    onChange={handleChangeSelect('qualification')}
                                    // className='atb-input-box-container'
                                    classNamePrefix='atb-input-box'
                                    onMenuOpen={() => setMenuOpen(true)}
                                    onMenuClose={() => setMenuOpen(false)}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            zIndex: 1000,
                                        }),
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{zIndex: 700, marginBottom: "12px"}}>
                                <InputLabel>Preferred Job Category</InputLabel>
                                <Select
                                    placeholder={isSmallScreen ? "Select Category": "Select Preferred Category"}
                                    options={departments?.map((item) => {
                                        return (
                                            { label: item.name, value: item.id }
                                        )
                                    })}
                                    value={careerData.departments}
                                    isMulti
                                    onChange={handleChangeSelectMultiValue('departments')}
                                    // className='atb-input-box-container'
                                    classNamePrefix='atb-input-box'
                                    onMenuOpen={() => setMenuOpen(true)}
                                    onMenuClose={() => setMenuOpen(false)}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            zIndex: 1000,
                                        }),
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{zIndex: 900, marginBottom: "12px"}}>
                                <InputLabel>Preferred Locations</InputLabel>
                                <Select
                                    placeholder={isSmallScreen ? "Select Locations" : "Select Preferred Locations"}
                                    options={district.map(item => {
                                        return (
                                            { label: item.name, value: item.id }
                                        )
                                    })
                                    }
                                    value={careerData.locations}
                                    isMulti
                                    onChange={handleChangeSelectMultiValue('locations')}
                                    // className='atb-input-box-container'
                                    classNamePrefix='atb-input-box'
                                    onMenuOpen={() => setMenuOpen(true)}
                                    onMenuClose={() => setMenuOpen(false)}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            zIndex: 1000,
                                        }),
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{zIndex: 800, marginBottom: "12px"}}>
                                <InputLabel>Preferred Organization Type</InputLabel>
                                <Select
                                    placeholder={isSmallScreen ? "Select Organization" : "Select Preferred Organization Type"}
                                    options={industries?.map((item) => {
                                        return (
                                            { label: item.name, value: item.id }
                                        )
                                    })}
                                    value={careerData.industries}
                                    isMulti
                                    onChange={handleChangeSelectMultiValue('industries')}
                                    // className='atb-input-box-container'
                                    classNamePrefix='atb-input-box'
                                    onMenuOpen={() => setMenuOpen(true)}
                                    onMenuClose={() => setMenuOpen(false)}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            zIndex: 1000,
                                        }),
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel>Looking for Job</InputLabel>
                                <Select
                                    placeholder="Select Job Status"
                                    options={options?.map((item) => {
                                        return (
                                            { label: item.label, value: item.value }
                                        )
                                    })}                                    
                                    value={careerData.searching_for_job_status ? {label: careerData.searching_for_job_status, value: careerData.searching_for_job_status} : null}
                                    onChange={handleChangeSelect('searching_for_job_status')}
                                    classNamePrefix='atb-input-box'
                                    onMenuOpen={() => setMenuOpen(true)}
                                    onMenuClose={() => setMenuOpen(false)}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            zIndex: 1000,
                                        }),
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </CandidateUpdateModal>
        </Box>
    )

}

export default UpdateCareerInfo