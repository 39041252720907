import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  deleteCandidateSaveJobInfo,
  postActivityLikes,
  postCandidateSaveJobInfo,
} from "../../../../fetchAPI";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  createTheme,
  ThemeProvider,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import TuneSharpIcon from "@mui/icons-material/TuneSharp";
import ChipComponent from "../../ChipComponent";
import EmployerProfilePicture from "../../EmployerComponents/EmployerProfilePicture";
import { CandidateContext } from "../../../context/CandidateContext";
import CXOApplyToJobModal from "../../Job/ApplyScreen/CXOApplyToJobModal";
import ApplyToJobModal from "../../Job/ApplyScreen/ApplyToJobModal";
import { fetchIpAddress } from "../../../helpers/helperFunctions";
import { UserContext } from "../../../context/UserContext";
import { useAlert } from "../../../context/AlertContext";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1700,
      xxxl: 2100,
    },
  },
});

function SaveJobIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.6875 1.71289C12.3186 1.71289 10.7965 3.26301 10 4.65574C9.20348 3.26301 7.68145 1.71289 5.3125 1.71289C2.30613 1.71289 0 4.01543 0 7.0693C0 10.3807 2.88922 12.6357 7.20363 16.05C9.31621 17.7204 9.45195 17.8473 10 18.2879C10.4473 17.9284 10.6948 17.7117 12.7964 16.05C17.1108 12.6357 20 10.3807 20 7.0693C20 4.01547 17.6939 1.71289 14.6875 1.71289Z"
        fill="#DB1616"
      />
    </svg>
  );
}

function QualificationIcon() {
  return (
    <svg
      width="19"
      height="12"
      viewBox="0 0 19 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1185_517)">
        <path
          d="M9.24204 6.20568C8.67357 6.21382 8.04876 5.92772 7.40767 5.71798C5.70415 5.15953 4.00438 4.59106 2.30336 4.02009C1.67729 3.81223 1.05811 3.60313 0.438306 3.38464C0.231704 3.31201 -0.00432234 3.22311 6.01223e-05 2.95828C0.00444258 2.69346 0.243601 2.60769 0.451455 2.5432C1.53663 2.20387 2.62286 1.86997 3.71012 1.5415C5.22771 1.08384 6.7503 0.644971 8.26224 0.171039C8.98023 -0.0609193 9.75302 -0.0609193 10.471 0.171039C12.8125 0.891014 15.1627 1.58282 17.5092 2.2884C17.7885 2.37229 18.0652 2.46682 18.3394 2.56511C18.5272 2.63398 18.7276 2.72726 18.7232 2.96454C18.7188 3.20182 18.5197 3.29574 18.3313 3.36147C17.682 3.58999 17.0303 3.80974 16.3773 4.02761C14.7063 4.58606 13.036 5.14638 11.3625 5.69607C10.7114 5.91456 10.0772 6.20381 9.24204 6.20568Z"
          fill="#8743DF"
        />
        <path
          d="M3.62329 7.12675C3.62329 6.63592 3.62329 6.1455 3.62329 5.6555C3.62329 5.14839 3.63331 5.13461 4.10223 5.28737C4.96495 5.56848 5.82391 5.85835 6.68412 6.14759C7.22755 6.32978 7.76784 6.52323 8.31189 6.70103C9.06317 6.94583 9.80819 6.91077 10.5538 6.65471C11.8748 6.20081 13.2002 5.75818 14.5256 5.31555C15.0947 5.12773 15.0966 5.12773 15.0966 5.71373C15.0966 6.63217 15.069 7.55123 15.1066 8.46842C15.1304 9.06067 14.8562 9.42943 14.401 9.72869C13.5483 10.2865 12.5986 10.5927 11.6119 10.793C10.0637 11.1097 8.46631 11.0986 6.92266 10.7604C5.9917 10.5576 5.09267 10.2596 4.29318 9.71929C3.83866 9.41315 3.56319 9.03062 3.61578 8.44274C3.65459 8.00575 3.62329 7.56437 3.62329 7.12675Z"
          fill="#8743DF"
        />
        <path
          d="M17.6758 5.73235C17.6758 5.76366 17.6758 5.79496 17.6758 5.82626C17.6142 6.52036 17.7441 7.21805 18.0514 7.84345C18.4702 8.71994 18.2611 9.27838 17.4616 9.82056C17.3202 9.91697 17.2112 9.92198 17.0653 9.8312C16.3141 9.36541 16.105 8.75312 16.4167 7.9361C16.5132 7.68568 16.6002 7.42523 16.7448 7.19422C16.8274 7.06274 16.8349 6.92063 16.8343 6.7735C16.8343 6.14744 16.8343 5.52137 16.8343 4.89531C16.8343 4.61045 17.3026 4.2586 17.5706 4.33873C17.6958 4.37567 17.6695 4.48523 17.6701 4.57351C17.6776 4.96104 17.6751 5.3467 17.6758 5.73235Z"
          fill="#8743DF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1185_517">
          <rect width="18.7237" height="11.02" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function LocationIcon() {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0C2.46699 0 0.40625 2.06074 0.40625 4.59375C0.40625 5.40455 0.620469 6.20168 1.02573 6.89895C1.1202 7.06151 1.22581 7.21992 1.33967 7.3698L4.80041 12H5.19957L8.66033 7.36983C8.77416 7.21992 8.87977 7.06153 8.97427 6.89897C9.37953 6.20168 9.59375 5.40455 9.59375 4.59375C9.59375 2.06074 7.53301 0 5 0ZM5 6.11719C4.15998 6.11719 3.47656 5.43377 3.47656 4.59375C3.47656 3.75373 4.15998 3.07031 5 3.07031C5.84002 3.07031 6.52344 3.75373 6.52344 4.59375C6.52344 5.43377 5.84002 6.11719 5 6.11719Z"
        fill="#ED7200"
      />
    </svg>
  );
}

function WorkHistoryIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6510_2644)">
        <path d="M0 9.15274C0.0655582 8.70586 0.118479 8.25741 0.218002 7.81606C0.607547 6.05713 1.47167 4.43822 2.71672 3.13475C4.07527 1.70026 5.70909 0.711856 7.63792 0.285099C11.2578 -0.515958 14.4351 0.369098 17.0886 2.96514C18.7078 4.55148 19.6371 6.50699 19.921 8.75871C20.5596 13.8214 17.2675 18.6088 12.2811 19.716C8.3954 20.5785 5.07247 19.4659 2.40788 16.5074C1.03787 14.9861 0.291851 13.1627 0.038307 11.1366C0.0260642 11.0396 0.0422586 10.9394 0.00118594 10.8456L0 9.15274ZM18.4037 10.36C18.6332 5.72526 14.9753 1.65648 10.1272 1.57602C5.48675 1.49911 1.60617 5.15375 1.5572 9.92735C1.51179 14.5164 5.1475 18.3931 9.96919 18.416C14.6649 18.4384 18.2536 14.6982 18.4041 10.36H18.4037Z" fill="#0079C1" />
        <path d="M12.6886 10.7769C11.8087 10.7769 10.9287 10.7769 10.0488 10.7769C9.54689 10.7769 9.25385 10.5114 9.20172 10.0133C9.19106 9.90913 9.18789 9.80382 9.1875 9.69772C9.1875 8.1106 9.1875 6.5236 9.1875 4.93674C9.1875 4.80619 9.1958 4.67485 9.20883 4.54469C9.24832 4.12898 9.60731 3.81108 10.0074 3.82883C10.2107 3.83425 10.404 3.91836 10.5464 4.0634C10.6889 4.20845 10.7693 4.40307 10.7708 4.60622C10.7779 4.96672 10.7731 5.32762 10.7731 5.68811V8.95386C10.7731 9.21575 10.7731 9.21614 11.0299 9.21614C12.4745 9.21614 13.9192 9.21417 15.3634 9.21614C15.9017 9.21614 16.265 9.69615 16.1276 10.2085C16.0848 10.3717 15.9887 10.5159 15.8546 10.6185C15.7205 10.7211 15.556 10.7761 15.3871 10.7749C14.4874 10.7784 13.5878 10.7765 12.6886 10.7769Z" fill="#0079C1" />
      </g>
      <defs>
        <clipPath id="clip0_6510_2644">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}

function ThumbsUpIcon({ color = "#1976D2", size = "24px" }) {
  return (
    <svg
      fill={color}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.2s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z" />
    </svg>
  )
}

function ThumbsUpFillIcon({ color = "#1976D2", size = "24px" }) {
  return (
    <svg
      fill={color}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
    </svg>
  )
}

//this component is used in several pages
export default function JobsCard({
  item,
  index,
  grid,
  candidateData,
  settingCandidateData,
}) {

  const { userData, isLoggedin } = useContext(UserContext)
  const alertContext = useAlert()
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { appliedJobsIds } = useContext(CandidateContext);
  const [openCxoApply, setOpenCxoApply] = useState(false);
  const [openJobApply, setOpenJobApply] = useState(false);
  const [applyJobInfo, setApplyJobInfo] = useState("");
  const isApplied = appliedJobsIds.includes(parseInt(item?.job_post?.post_id ? item?.job_post?.post_id : item?.post_id));
  const isCXOJobs = item?.job_post?.is_cxo ? item?.job_post?.is_cxo == 1 : item?.is_cxo == 1
  const isDashboardPage = history.location.pathname.includes("/candidate-dashboard");
  const isAppliedPage = history.location.pathname.includes("/applied-jobs");
  const isSavedJobPage = history.location.pathname.includes("/saved-jobs");
  const [likeLoading, setLikeLoading] = useState(false)
  const [likedJob, setLikedJob] = useState(false)
  const [likeCount, setlikeCount] = useState(0)
  const pageTitle = isCXOJobs ? 'cxo-jobs' : 'regular-jobs'
  const currentPageURL = window.location.href;
  const userAgent = navigator.userAgent;
  const isEmployer = +userData?.user_role_id === 3
  let isExpired = false

  const displaySalary = (item) => {
    const jobPost = item?.job_post || item;

    if (jobPost?.negotiable) {
      return "Negotiable";
    }

    const minSalary = jobPost?.minimum_salary;
    const maxSalary = jobPost?.maximum_salary;
    const currency = jobPost?.salary_currency?.name || 'Taka';
    const salaryType = jobPost?.salary_type?.type || jobPost?.salary_type_data?.type || "";

    if (maxSalary !== null) {
      return `${minSalary} - ${maxSalary} ${currency}/${salaryType}`;
    } if (minSalary !== null) {
      return `${minSalary} ${currency}/${salaryType}`;
    } else {
      return "Negotiable";
    }
  };

  const dayLeft = (date) => {
    const today = moment(new Date(), 'YYYY-MM-DD').startOf('day');
    const targetDate = moment(date, 'YYYY-MM-DD').startOf('day');

    let days = targetDate.diff(today, 'days');
    if (days >= 0) {
      return days
    } else {
      isExpired = true;
      return null
    }
  };

  const [saveLoading, setSaveLoading] = useState(false);

  const unSaveCandidate = (candId, jobId) => {
    if (isLoggedin) {
      setSaveLoading(false);
      setLoading(true);

      deleteCandidateSaveJobInfo(candId, jobId)
        .then((response) => {
          if (response && response.success) {
            settingCandidateData();
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      history.push("/login")
    }

  };

  const saveCandidate = (candId, jobId) => {
    if (isLoggedin) {
      setLoading(false);
      setSaveLoading(true);

      postCandidateSaveJobInfo(candId, jobId)
        .then((response) => {
          if (response && response.success) {
            settingCandidateData();
          } else {
            if (
              candidateData?.id == 0 &&
              response.errors[0].message === "Unauthenticated."
            ) {
              history.push("/login");
            } else {
              console.log(response);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setSaveLoading(false);
        });

    } else {
      history.push("/login")
    }
  };

  const likeJobPost = async (id) => {
    if (candidateData?.id === 0 && !isLoggedin) {
      history.push("/login");
    } else {
      if (!likedJob) {
        setLikeLoading(true)
        const ipAddress = await fetchIpAddress();
        const params = {
          page_title: pageTitle,
          action_type: 'view',
          page_url: currentPageURL,
          ip: ipAddress,
          job_post_id: id,
          platform: "web",
          user_agent: userAgent
        };
        await postActivityLikes(params).then(res => {
          if (res && res.success) {
            setLikeLoading(false)
            setLikedJob(true)
            setlikeCount(item?.like_count + 1)
          } else {
            setLikeLoading(false)
            alertContext.setOpenErrorAlert(true);
            alertContext.setResponseMessage(`${res.errors.code}: ${res.errors.message}`);
          }
        });
      }
    }
  }

  useEffect(() => {
    if (item?.is_user_like) {
      setLikedJob(true)
    }

    if (item?.like_count) {
      setlikeCount(item?.like_count)
    }
  }, [item.is_user_like, item.like_count])

  const handleOpenCxoModal = () => {
    // gatis for job search page. Need to fix immidiatly
    setApplyJobInfo(item);
    if (isCXOJobs) {
      setOpenCxoApply(true);
    } else {
      setOpenJobApply(true);
    }
  };

  const displayTitle = (title) => {
    if (title?.length > 10) {
      let concateText = title.substring(0, 15) + '...'
      return concateText
    } else {
      return title
    }
  }

  return (
    <Card
      key={index}
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "5px",
      }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Box
            sx={{
              flexGrow: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              padding: '5px'
            }}
          >
            <Stack spacing={0} alignItems={"baseline"}>
              {/* Job Information */}
              <Box>
                <Tooltip title={item?.job_post?.job_title ? item?.job_post?.job_title : item?.job_title}>
                  <Link
                    to={
                      isCXOJobs
                        ? `/cxo-jobs/${item?.job_post?.post_id ? item?.job_post?.post_id : item?.post_id}`
                        : `/jobs/${item?.job_post?.post_id ? item?.job_post?.post_id : item?.post_id}`
                    }
                  >
                    <Typography
                      className="text-left text-truncate"
                      variant="h6"
                      align="left"
                      sx={{
                        width: {
                          xs: "9rem",
                          sm: "23rem",
                          md: `${isDashboardPage ? "7.5rem" : "9rem"}`,
                          lg: `${isDashboardPage ? "9rem" : "16rem"}`,
                          xl: `${isDashboardPage ? "16rem" : "22rem"}`,
                        },
                        fontSize: "18px",
                        color: "#0275D8",
                        fontWeight: "600",
                        // wordBreak: "break-word",
                        // "@media (max-width: 1200px)": {
                        //   maxWidth: "12rem",
                        // },
                        // "@media (max-width: 600px)": {
                        //   fontSize: "14px",
                        //   maxWidth: "18rem",
                        //   whiteSpace:"break-spaces"
                        // },
                      }}
                    >
                      {(item?.job_post?.job_title ? item?.job_post?.job_title : item?.job_title)}
                    </Typography>
                  </Link>
                </Tooltip>
              </Box>
              {/* Profile Information */}
              <Typography
                className="text-left text-truncate"
                align="left"
                sx={{
                  width: {
                    xs: "9rem",
                    sm: "23rem",
                    md: `${isDashboardPage ? "7.5rem" : "9rem"}`,
                    lg: `${isDashboardPage ? "9rem" : "16rem"}`,
                    xl: `${isDashboardPage ? "16rem" : "22rem"}`,
                  },
                  fontSize: "16px",
                  color: "#72737C",
                  fontWeight: "600",
                  fontFamily: "Epilogue,sans-serif",
                  // "@media (max-width: 1200px)": {
                  //   maxWidth: "16rem",
                  // },
                  // "@media (max-width: 600px)": {
                  //   fontSize: "12px",
                  //   maxWidth: "10rem",
                  // },
                }}
              >
                {isCXOJobs ? (
                  candidateData?.is_cxo_eligible || (userData?.id && userData?.id === item.employer?.user_id) ? (
                    item.employer?.employer_name || <span style={{ visibility: "hidden" }}>.</span>
                  ) : (
                    <div style={{ position: 'relative', width: '8rem', height: '24px', borderRadius: '5px', display: 'flex', alignItems: 'center', userSelect: 'none' }}>
                      <span style={{ filter: 'blur(4px)' }} className="text-truncate">{item.employer?.employer_name || 'Placeholder text'}</span>
                    </div>
                  )
                ) : (
                  item?.employer?.employer_name || item?.author_id[0]?.employer_name || <span style={{ visibility: "hidden" }}>.</span>
                )}
              </Typography>
            </Stack>
            {userData?.user_role_id !== 3 ?
              <Stack direction="row" spacing={1}>
                {candidateData.id !== 0 &&
                  candidateData?.savedJobIds.includes(parseInt(item?.job_post?.post_id ? item?.job_post?.post_id : item?.post_id)) ? (
                  <Tooltip title="Saved" placement="top" arrow>
                    <LoadingButton
                      loading={loading}
                      variant='outlined'
                      size='small'
                      onClick={() => unSaveCandidate(candidateData?.id, item?.job_post?.post_id ? item?.job_post?.post_id : item?.post_id)}
                      sx={{
                        flexWrap: 'nowrap',
                        boxShadow: 0,
                        lineHeight: '0px',
                        padding: "3px",
                        height: '30px',
                        minWidth: "50px",
                        ":focus": { outline: "none" }
                      }}
                    >
                      {!loading ? <SaveJobIcon /> : null}
                    </LoadingButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Save Job" placement="top" arrow>
                    <LoadingButton
                      loading={saveLoading}
                      variant='outlined'
                      size='small'
                      onClick={() =>
                        saveCandidate(candidateData?.id, {
                          job_post_id: item?.job_post?.post_id ? item?.job_post?.post_id : item?.post_id,
                        })
                      }
                      sx={{
                        flexWrap: 'nowrap',
                        boxShadow: 0,
                        lineHeight: '0px',
                        padding: "3px",
                        height: '30px',
                        minWidth: "50px",
                        ":focus": { outline: "none" }
                      }}
                    >
                      {!saveLoading ? <FavoriteBorderIcon /> : null}
                    </LoadingButton>
                  </Tooltip>
                )}

                <Tooltip title={`${likedJob ? "Liked" : "Like Job"} - ${likeCount}`} placement="top" arrow>
                  <LoadingButton
                    loading={likeLoading}
                    variant='outlined'
                    size='small'
                    onClick={() => { likeJobPost(item?.job_post?.post_id || item?.post_id) }}
                    sx={{
                      flexWrap: 'nowrap',
                      boxShadow: 0,
                      lineHeight: '0px',
                      padding: "3px",
                      height: '30px',
                      minWidth: "50px",
                      ":disabled": likedJob,
                      cursor: "pointer",
                      ":focus": { outline: "none" }
                    }}
                  >
                    {!likeLoading ? (
                      likedJob ? (
                        <ThumbsUpFillIcon />
                      ) : (
                        <ThumbsUpIcon />
                      )
                    ) : null}
                  </LoadingButton>
                </Tooltip>
              </Stack>
              : null}
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Stack spacing={1} direction={"row"} alignItems={"center"}>
                {grid ? (
                  <React.Fragment>
                    <img src="/images/candidate/salaryIcon.png" alt="" />

                    {/* Salary Information */}
                    <Typography
                      variant="subheading"
                      align="left"
                      sx={{
                        fontSize: "14px",
                        color: "#1D1E1B",
                        fontWeight: "600",
                        fontFamily: "Epilogue,sans-serif",
                        "@media (max-width: 600px)": {
                          fontSize: "12px",
                        },
                      }}
                    >
                      Salary: {displaySalary(item)}
                    </Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box
                      sx={{
                        flexGrow: 0,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box my={2}>
                        <img src="/images/candidate/salaryIcon.png" alt="" />
                      </Box>

                      {/* Salary Information */}
                      <Typography
                        variant="subheading"
                        align="left"
                        sx={{
                          fontSize: "14px",
                          color: "#1D1E1B",
                          fontWeight: "600",
                          marginLeft: '10px',
                          "@media (max-width: 600px)": {
                            fontSize: "12px",
                          },
                        }}
                      >
                        Salary: {displaySalary(item)}
                      </Typography>
                    </Box>

                    <Box>
                      <Stack direction={"row"} spacing={2}>
                        <ChipComponent title={item?.job_post?.job_type?.name ? item?.job_post?.job_type?.name : item?.job_type?.name} />
                        <Chip
                          label={item?.job_post?.job_level?.candidate_level_name ? item?.job_post?.job_level?.candidate_level_name : item?.job_level?.candidate_level_name}
                          avatar={
                            <Avatar sx={{ backgroundColor: "transparent" }}>
                              {QualificationIcon()}
                            </Avatar>
                          }
                          sx={{
                            backgroundColor: "#F2E9FF",
                            color: "#8743DF",
                            fontWeight: 600,
                          }}
                        />
                        <Chip
                          label={item?.job_post?.qualification?.name ? item?.job_post?.qualification?.name : item?.qualification?.name}
                          avatar={
                            <Avatar sx={{ backgroundColor: "transparent" }}>
                              {LocationIcon()}
                            </Avatar>
                          }
                          sx={{
                            backgroundColor: "#FFF5E2",
                            color: "#ED7200",
                            fontWeight: 600,
                          }}
                        />
                      </Stack>
                    </Box>
                  </React.Fragment>
                )}
              </Stack>
            </Grid>
            {isCXOJobs ? (
              candidateData?.is_cxo_eligible || (userData?.id && userData?.id === item.employer?.user_id) ? (
                <Grid item>
                  <Tooltip title="Go to Profile">
                    <Link
                      to={
                        item?.author_id[0]
                          ? `/employer/profile/${item?.author_id[0].employer_name}`
                          : `/employer/profile/${item?.employer?.slug}`
                      }
                      style={{
                        display: 'flex',
                      }}
                    >
                      <EmployerProfilePicture
                        profile_picture={
                          item?.author_id[0]
                            ? `${process.env.REACT_APP_S3_BUCKET}${item?.author_id[0].profile_pic}`
                            : item?.employer?.profile_pic_base64
                        }
                        height={grid ? 60 : 120}
                        width={grid ? 60 : 120}
                      />
                    </Link>
                  </Tooltip>
                </Grid>
              ) : (
                <div style={{ position: 'relative', height: grid ? '60px' : '120px', width: grid ? '60px' : '120px', background: '#DADADA', borderRadius: '5px', overflow: 'hidden' }}>
                  <div style={{ filter: 'blur(8px)', width: '100%', height: '100%' }}>
                    <img src={item?.author_id[0]
                      ? `${process.env.REACT_APP_S3_BUCKET}${item?.author_id[0].profile_pic}`
                      : item?.employer?.profile_pic_base64} alt="Blurry content" style={{ width: '100%', height: '100%' }} onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()} />
                  </div>
                </div>
              )
            ) : (
              <Grid item>
                <Tooltip title="Go to Profile">
                  <Link
                    to={
                      isDashboardPage
                        ? `/employer/profile/${item?.author_id}`
                        : item?.author_id[0]
                          ? `/employer/profile/${item?.author_id[0].employer_name}`
                          : `/employer/profile/${item?.employer?.slug}`
                    }
                    style={{
                      display: 'flex',
                    }}
                  >
                    <EmployerProfilePicture
                      profile_picture={
                        item?.author_id[0]
                          ? `${process.env.REACT_APP_S3_BUCKET}${item?.author_id[0].profile_pic}`
                          : item?.employer?.profile_pic_base64 ||
                          item?.employer?.profile_pic_s3
                      }
                      height={grid ? 60 : 120}
                      width={grid ? 60 : 120}
                    />
                  </Link>
                </Tooltip>
              </Grid>
            )}
          </Box>
          {grid ? (
            <Stack direction={"row"} spacing={2}>
              <ChipComponent
                title={item?.job_post?.job_type?.name ? item?.job_post?.job_type?.name : item?.job_type?.name ? item?.job_type?.name : "" || item?.job_post?.job_type_data?.name ? item?.job_post?.job_type_data?.name : item?.job_type_data?.name ? item?.job_type_data?.name : "" || "N/A"}
              />
              <Chip
                label={item?.job_post?.qualification?.name ? item?.job_post?.qualification?.name : item?.qualification?.name || "N/A"}
                avatar={
                  <Avatar sx={{ backgroundColor: "transparent" }}>
                    {QualificationIcon()}
                  </Avatar>
                }
                sx={{
                  backgroundColor: "#F2E9FF",
                  color: "#8743DF",
                  fontWeight: 600,
                  fontSize: "12px",
                  fontFamily: 'Poppins, sans-serif',
                  marginBottom: 1
                }}
              />
              <Chip
                className="text-truncate"
                label={item?.job_post?.district?.name ? item?.job_post?.district?.name : item?.district?.name || "N/A"}
                avatar={
                  <Avatar sx={{ backgroundColor: "transparent" }}>
                    {LocationIcon()}
                  </Avatar>
                }
                sx={{
                  backgroundColor: "#FFF5E2",
                  color: "#ED7200",
                  fontWeight: 600,
                  maxWidth: "8rem",
                  fontSize: "12px",
                  fontFamily: 'Poppins, sans-serif',
                  marginBottom: 1,
                  filter: isCXOJobs ? candidateData?.is_cxo_eligible || (userData?.id && userData?.id === item.employer?.user_id) ? "none" : "blur(2px)" : "none",
                  userSelect: isCXOJobs ? candidateData?.is_cxo_eligible || (userData?.id && userData?.id === item.employer?.user_id) ? "auto" : "none" : 'auto',
                }}
              />
            </Stack>
          ) : null}

          {grid ? null : (
            <Stack direction={"row"} sx={{ flexWrap: "wrap", marginTop: "40px !important" }}>
              {item?.job_skill && item.job_skill.length > 0 ? (
                item.job_skill.map((i, index) => {
                  if (i && i.skill && i.skill.skill_name) {
                    return (
                      <Chip
                        key={index}
                        label={i.skill.skill_name}
                        variant="outlined"
                        sx={{
                          borderColor: "#42A5F5",
                          color: "#42A5F5",
                          borderRadius: "5px",
                          margin: "0px 8px 8px 0px"
                        }}
                      />
                    );
                  }
                  return null; // Return null if i or i.skill is not properly defined
                })
              ) : (
                //in case item.skills is empty or undefined
                <div>
                  {/* No skills available */}
                </div>
              )}
            </Stack>
          )}
        </Stack>
      </CardContent>
      <Divider sx={{ marginTop: 1 }} />
      <ThemeProvider theme={theme}>
        <CardActions sx={{ p: 2, }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row", md: "column", xxl: isDashboardPage ? "column" : "row", xxxl: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "start", sm: "center", md: "start", xxl: isDashboardPage ? "start" : "center", xxxl: "center" },
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", height: { md: "42px", lg: isDashboardPage ? "42px" : "auto", xl: "auto" } }}>
              <Box
                sx={{
                  minWidth: "35px",
                  minHeight: "35px",
                  backgroundColor: "#E9F5FF",
                  borderRadius: "50%",
                  position: "relative",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <WorkHistoryIcon />
              </Box>
              <Box
                className="text-body"
                sx={{
                  marginLeft: "10px",
                  fontSize: "14px",
                  color: "#1D1E1B",
                  fontWeight: "600",
                  fontFamily: "Epilogue,sans-serif",
                }}
              >
                <span>Deadline: </span>
                {(item?.job_post?.job_deadline || item?.job_deadline) ?
                  <span>{moment(item?.job_post?.job_deadline ? item?.job_post?.job_deadline : item?.job_deadline).format("MMM Do, YY")}</span>
                  :
                  "N/A"
                }
                &nbsp;
                <span style={{ color: "#DB1616" }}>
                  {
                    (() => {
                      const daysLeft = dayLeft(item?.job_post?.job_deadline || item?.job_deadline);
                      return daysLeft === 0 ? `(Expiring Today)` : daysLeft === 1 ? `(${daysLeft} day left)` : daysLeft > 1 && daysLeft < 8 ? `(${daysLeft} days left)` : ``;
                    })()
                  }
                </span>
              </Box>
            </Box>

            <Stack
              sx={{ width: { xs: "100%", sm: "auto", md: "100%", xxl: isDashboardPage ? "100%" : "auto", xxxl: "auto" } }}
              spacing={2}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Link
                to={
                  isCXOJobs
                    ? `/cxo-jobs/${item?.job_post?.post_id ? item?.job_post?.post_id : item?.post_id}`
                    : `/jobs/${item?.job_post?.post_id ? item?.job_post?.post_id : item?.post_id}`
                }
              >
                <Button
                  className="job_details_btn_click"
                  variant="outlined"
                  sx={{
                    fontSize: { xs: "10px", md: "12px", lg: "14px" },
                    marginY: 1,
                    // marginLeft: 1,
                    fontFamily: "Epilogue,sans-serif",
                    fontWeight: "700",
                    textTransform: "capitalize",
                    padding: "6px 10px",
                    '&:hover': {
                      backgroundColor: '#1976d2',
                      color: 'white'
                    },
                  }}
                >
                  View Details
                </Button>
              </Link>
              {!isEmployer && !isAppliedPage ? (
                <Box sx={{ cursor: `${isSavedJobPage && isExpired ? 'default' : 'pointer'}` }}>
                  <Button
                    className='ga_apply_button'
                    variant="contained"
                    onClick={!isApplied && handleOpenCxoModal}
                    disabled={isExpired && isSavedJobPage}
                    sx={{
                      color: `${isApplied ? "#15A449" : "#ffffff"}`,
                      fontSize: { xs: "10px", md: "12px", lg: "14px" },
                      marginY: 1,
                      fontFamily: "Epilogue,sans-serif",
                      fontWeight: "700",
                      backgroundColor: `${isApplied ? "#E5F5FF" : "#00A264"}`,
                      textTransform: "capitalize",
                      padding: "6px 10px",
                      cursor: `${isApplied && "default"}`,
                      ":focus": { outline: "none" },
                      "&:hover": {
                        backgroundColor: `${isApplied ? "#E5F5FF" : "#00A264"}`,
                      },
                    }}
                  >
                    {isApplied ? "Applied" : "Apply Now"}
                  </Button>
                </Box>
              ) : null}

              {isAppliedPage && item?.apply_status[0]?.name ?
                <Link to="#">
                  <Button
                    variant="contained"
                    onClick={!isApplied && handleOpenCxoModal}
                    sx={{
                      color: `${isApplied ? "#15A449" : "#ffffff"}`,
                      fontSize: { xs: "10px", md: "12px", lg: "14px" },
                      marginY: 1,
                      fontFamily: "Epilogue,sans-serif",
                      fontWeight: "700",
                      backgroundColor: `${isApplied ? "#E5F5FF" : "#00A264"}`,
                      textTransform: "capitalize",
                      padding: "6px 10px",
                      cursor: `${isApplied && "default"}`,
                      ":focus": { outline: "none" },
                      "&:hover": {
                        backgroundColor: `${isApplied ? "#E5F5FF" : "#00A264"}`,
                      },
                    }}
                  >
                    {item?.apply_status[0]?.name === "Applied" ? "Applied" : item?.apply_status[0]?.name === "In Review" ? "In Review" : item?.apply_status[0]?.name === "Rejected" ? "Rejected" : item?.apply_status[0]?.name === "ShortList" ? "ShortList" : item?.apply_status[0]?.name === "Interview" ? "Interview" : item?.apply_status[0]?.name === "Selected" ? "Selected" : item?.apply_status[0]?.name === "Hired" ? "Hired" : "Apply"}
                  </Button>
                </Link>
                :
                null
              }
            </Stack>
          </Box>
        </CardActions>
      </ThemeProvider>
      {/* open modal to apply cxo job */}
      <CXOApplyToJobModal
        open={openCxoApply}
        jobInfo={applyJobInfo.job_post}
        handleClose={() => setOpenCxoApply(false)}
      />
      {/* open modal to apply regular job */}
      <ApplyToJobModal
        open={openJobApply}
        jobInfo={applyJobInfo.job_post}
        handleClose={() => setOpenJobApply(false)}
      />
    </Card>
  );
}
