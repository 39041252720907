import React from "react";
import { Box, Card, Grid } from "@mui/material";
import { useState } from "react";
import MessageBoxDrawer from "./components/MessageBoxDrawer";
import StatList from "./components/StatList";
import MessageBox from "./components/MessageBox";

function DirectScoutingInbox() {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div>
      <Box sx={{position:'relative',zIndex:20,width:'100%',marginTop:'10px',marginBottom:'10px',paddingX:{lg:'50px',md:'30px',sm:'10px',xs:'5px'}}}>
        <Card sx={{ background: "var(--app-bg-color-off-white)" }}>
          <Grid container>
            <Grid item xs={12} md={5} sx={{ height: "calc(100vh - 170px)" }}>
              <StatList setOpenDrawer={setOpenDrawer} />
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <MessageBox/>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" }, mt: "150px" }}>
        <MessageBoxDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      </Box>
    </div>
  );
}

export default DirectScoutingInbox;
