import React, { useContext, useEffect, useState } from "react"
import styles from '../../css/v2/companyList.module.css'
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import { Box, Button, Card, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Pagination, Radio, RadioGroup, Skeleton, Stack, TextField, ThemeProvider, Typography } from "@mui/material";
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import Select from 'react-select'
import { DropdownContext } from "../context/DropdownContext";
import { useQuery } from "react-query";
import { searchCompany } from "../../fetchAPI";
import { Link } from 'react-router-dom'
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import makeStyles from '@material-ui/core/styles/makeStyles';
import GlobalLoader from "../components/GlobalLoader";
import { createTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import NoResultsFound from "../components/NoResultsFound";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      tablet: 769,
      md: 900,
      lg: 1200,
      xl: 1400,
      xxl: 1920,
    },
  },
});

function ClearFilterIcon() {
    return (
        <Box
            sx={{
                width: ' 32px',
                height: ' 32px',
                marginLeft: ' 8px',
                backgroundColor: ' #B6DDFF',
                display: ' flex',
                alignItems: ' center',
                justifyContent: ' center',
                borderRadius: ' 50%',
            }}
        >
            <TuneSharpIcon className="text-primary" />
        </Box>
    )
}

export default function CompanyList() {

    const urlParams = new URLSearchParams(window.location.search);
    const top_companies = urlParams.get("top_companies");

    const useStyles = makeStyles(() => ({
        ul: {
            '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                    background: '#DB1616',
                    color: 'white',
                },
            },
        },
        customTextField: {
            "& input::placeholder": {
                fontSize: "14px"
            }
        }
    }));

    const muiClasses = useStyles()

    const { departments, industries, apiCallingConditionSet, setApiCallingConditionSet } = useContext(DropdownContext);

    const [pageNumber, setPageNumber] = useState(1)

    const [employerName, setEmployerName] = useState('');
    const [employerType, setEmployerType] = useState('');
    const [employerLocation, setEmployerLocation] = useState('');
    const [defaultSelectDepartment, setDefaultSelectDepartment] = useState('')
    const [department, setDepartment] = useState('');
    const [defaultSelectIndustry, setDefaultSelectIndustry] = useState('')
    const [industry, setIndustry] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [topFeaturedCompanies, setTopFeaturedCompanies] = useState(false);

    const [companySearchData, setCompanySearchData] = useState({})

    const [sideBarOpen, setSideBarOpen] = useState(true);

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleBusinessTypeChange = (event) => {
        setBusinessType(event.target.value);
        setCompanySearchData({ ...companySearchData, nature_of_business: event.target.value })
    };

    const changePage = (event, value) => {
        setPageNumber(value);
    }

    const resetFilterForm = () => {
        // setTotalData([]);
        setDefaultSelectDepartment(null)
        setDefaultSelectIndustry(null)
        setDepartment('')
        setIndustry('')
        setEmployerName('')
        setEmployerType('')
        setEmployerLocation('')
        setBusinessType('');
        setCompanySearchData({ ...companySearchData, is_top_featured_companies: top_companies !== null ? true : false })
        // setTopFeaturedCompanies(true);
        setPageNumber(1);
    }

    const handleResize = () => {
        if (window.matchMedia("(max-width: 768px)").matches) {
            setSideBarOpen(false);
        } else {
            setSideBarOpen(true);
        }
    };

    const handleWindowClick = (e) => {
        if (window.innerWidth <= 768 && !e.target.matches(`.${styles.filterFormSideBar},.${styles.filterFormSideBar} *,.sidebarOpenButton`)) {
            setSideBarOpen(false)
        }
    }

    const handleSearchButtonClick = (e) => {
        setCompanySearchData({ ...companySearchData });
        if (window.innerWidth <= 768) {
            setSideBarOpen(false)
        }
    }

    useEffect(() => {
        setTopFeaturedCompanies(Boolean(top_companies))
    }, [])

    useEffect(() => {
        setApiCallingConditionSet({
            ...apiCallingConditionSet,
            department: true,
            industry: true,
        })
    }, [])

    useEffect(() => {

        if (window.innerWidth < 768) {
            setSideBarOpen(false)
        } else {
            setSideBarOpen(true);
        }

        window.addEventListener('resize', handleResize);
        window.addEventListener('click', handleWindowClick)
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('click', handleWindowClick);
        };
    }, []);

    useEffect(() => {
        setTotalData([]);

        setCompanySearchData({
            employer_name: employerName,
            company_type: employerType,
            map_location: employerLocation,
            department: department,
            industry: industry,
            nature_of_business: businessType,
            is_top_featured_companies: topFeaturedCompanies
        })
    }, [employerName, employerType, employerLocation, businessType, topFeaturedCompanies, department, industry]);

    useEffect(() => {
        resetFilterForm()
    }, [])

    const { data, isLoading } = useQuery(['companyList', companySearchData, pageNumber], searchCompany, { staleTime: Infinity })

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [isLoading])

    const [isAtBottom, setIsAtBottom] = useState(false);
    const [totalData, setTotalData] = useState([]);
    const [currentPage, setCurrentPage] = useState();
    const [lastpage, setLastPage] = useState();
    const [count, setCount] = useState();
    const [isLazyLoading, setIsLazyLoading] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          const scrollTop = window.scrollY; 
          const viewportHeight = window.innerHeight; 
          const documentHeight = document.documentElement.scrollHeight; 
    
          // Check if the user is at the bottom
          if (scrollTop + viewportHeight >= documentHeight) { 
            setIsAtBottom(true);
            if(lastpage > currentPage){
                setPageNumber(pageNumber + 1) 
            }

          } else {
            setIsAtBottom(false);
          }
        };
    
        window.addEventListener("scroll", handleScroll);
    
        // Cleanup the event listener on component unmount
        return () => window.removeEventListener("scroll", handleScroll);
      }, [currentPage, lastpage, pageNumber]);

      useEffect(() => {
        if(lastpage > currentPage) {
            setIsLazyLoading(true);
        }
          
        if(data?.response?.data) {
            setTotalData((prev) => {
                const newData = data.response.data.filter(
                    (newItem) => !prev.some((existingItem) => existingItem.id === newItem.id)
                );
                return [...prev, ...newData];
            });
            
            setCurrentPage(data?.response?.pagination?.current_page)
            setLastPage(data?.response?.pagination?.last_page)
            setCount(data?.response?.pagination?.count)

            // setTimeout(() => {
            // }, 10000);
            setIsLazyLoading(false)
        }
        

      }, [data?.response?.data])

      
    return (
        <Box
            sx={{
                backgroundColor: '#f9f9f9'
            }}
        >
        <ThemeProvider theme={theme}>
            {/* <div className={`${styles.filterFormSideBar} ${sideBarOpen ? styles.active : styles.inactive}`}> */}
            <Grid container spacing={0}>
                {/* <div className={styles.sideBarCloseIcon} onClick={() => setSideBarOpen(false)}>
                    <CloseSharpIcon />
                </div> */}
                <Grid item tablet={3}>
                    <Box 
                        sx={{
                            display: { xs: 'none', tablet: 'block' },
                            maxHeight: 'calc(100vh - 100px)',
                            position: 'sticky',
                            top: 100,
                            zIndex: 1000,
                            overflowY: 'auto',
                            boxShadow: '-1px 3px 5px 2px rgb(0 0 0 / 18%)'
                        }}
                    >
                        <Box p={3}
                        sx={{
                            backgroundColor: '#FFFF',
                            width: '100%',
                            '@media (max-width: 600px)': {
                                width: '100%',
                            },
                            }}>
                            <form>
                                <Stack spacing={2}>
                                    <Stack
                                        spacing={2}
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '14px',
                                                '@media (max-width: 600px)': {
                                                    fontSize: '12px',
                                                },
                                            }}
                                        >
                                            All Filters
                                        </Typography>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            endIcon={<ClearFilterIcon />}
                                            disableElevation
                                            sx={{
                                                backgroundColor: 'white',
                                                color: '#0275D8',
                                                borderRadius: '30px',
                                                '&:hover': {
                                                    backgroundColor: '#e6f3ff',
                                                },
                                                '@media (max-width: 600px)': {
                                                    fontSize: '12px',
                                                },
                                            }}
                                            onClick={() => resetFilterForm()}
                                        >
                                            Clear Filter
                                        </Button>
                                    </Stack>
                                    <TextField
                                        label="Company Name"
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        sx={{  backgroundColor: 'white' }}
                                        multiline
                                        value={employerName} 
                                        onChange={(e) => setEmployerName(e.target.value)} onBlur={(e) => setCompanySearchData({ ...companySearchData, employer_name: e.target.value })}
                                    />
                                    <TextField
                                        label="Company type"
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        sx={{  backgroundColor: 'white' }}
                                        multiline
                                        value={employerType} 
                                        onChange={(e) => setEmployerType(e.target.value)} onBlur={(e) => setCompanySearchData({ ...companySearchData, company_type: e.target.value })}
                                    />
                                    <TextField
                                        label="Location"
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                        sx={{  backgroundColor: 'white' }}
                                        multiline
                                        value={employerLocation} 
                                        onChange={(e) => setEmployerLocation(e.target.value)} onBlur={(e) => setCompanySearchData({ ...companySearchData, map_location: e.target.value })}
                                    />
                                    <Select
                                        isClearable
                                        placeholder='Department'
                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                        className='react-select-container'
                                        classNamePrefix='react-select'
                                        options=
                                        {departments.map(item => {
                                            return (
                                                { label: item.name, value: item.id }
                                            )
                                        })
                                        }
                                        onChange={(e) => {
                                            const selectedValue = e ? { label: e.label, value: e.value } : null;
                                            setCompanySearchData({ ...companySearchData, department: e?.value || '' });
                                            setDepartment(e?.value || '');
                                            setDefaultSelectDepartment(selectedValue);
                                        }}                                        
                                        value={defaultSelectDepartment }
                                        menuPortalTarget={document.body}
                                    />
                                    <Select
                                        isClearable
                                        placeholder='Industry'
                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                        className='react-select-container'
                                        classNamePrefix='react-select'
                                        options=
                                        {industries.map(item => {
                                            return (
                                                { label: item.name, value: item.id }
                                            )
                                        })
                                        }
                                        onChange={(e) => {
                                            const selectedValue = e ? { label: e.label, value: e.value } : null;
                                            setCompanySearchData({ ...companySearchData, industry: e?.value || '' });
                                            setIndustry(e?.value || '');
                                            setDefaultSelectIndustry(selectedValue);
                                        }} 
                                        value={defaultSelectIndustry}
                                        menuPortalTarget={document.body}
                                    />

                                    {/* <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={topFeaturedCompanies} onChange={(e) => setTopFeaturedCompanies(e.target.checked)} />} label="Top Featured Companies" />
                                    </FormGroup> */}

                                    <FormControl>
                                        <FormLabel id="nature_of_business_label" sx={{ margin: '10px 0px !important', color: 'black', fontSize: 18, fontFamily: 'Epilogue', fontWeight: '700', wordWrap: 'break-word' }}>Nature of Business</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="nature_of_business_label"
                                            name="nature_of_business"
                                            value={businessType}
                                            onChange={handleBusinessTypeChange}
                                        >
                                            <FormControlLabel sx={{ color: 'black' }} value="b2b" control={<Radio />} label="B2B" />
                                            <FormControlLabel sx={{ color: 'black' }} value="b2c" control={<Radio />} label="B2C" />

                                        </RadioGroup>
                                    </FormControl>

                                    {/* <button type="button" class="btn btn-dark">Search By Benefit</button> */}
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        startIcon={<SearchIcon />}
                                        sx={{
                                            backgroundColor: '#0079C1',
                                            color: 'white',
                                            fontSize: '12px',
                                            whiteSpace: "nowrap",
                                            textTransform: 'capitalize',
                                            '&:hover': {
                                                backgroundColor: '#0079C1',
                                            },
                                            paddingY: '12px',
                                        }}
                                        onClick={handleSearchButtonClick}
                                    >
                                        Search
                                    </Button>
                                </Stack>

                            </form>
                        </Box>
                    </Box>
                </Grid>
                <Grid item tablet={9} sx={{ width: '100%' }}>
                    {/* <div className="text-c mb-3">
                        <button className={`btn btn-primary sidebarOpenButton ${sideBarOpen ? styles.inactive : styles.active}`} onClick={() => { setSideBarOpen(true) }}>Open Advanced Search Filter</button>
                    </div> */}
                    <Box 
                        sx={{
                            margin: 4,
                            '@media (max-width: 600px)': {
                                margin: 2,
                            },
                        }}
                    >
                        <Grid container
                            sx={{
                                display: "flex", 
                                flexDirection: {xs: "column", sm: "row"},
                                justifyContent: "space-between", 
                                alignItems: {xs: "flex-start", sm: "center"},
                                boxShadow: '0px 4px 4px 0px #00000040',
                                background: "#ffffff",
                                borderRadius: "4px",
                                p: 2,
                                my: 3
                            }}
                        >
                            <Grid item sx={{ width: "100%"}}>
                                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '20px',
                                            fontFamily: 'Epilogue',
                                            '@media (max-width: 600px)': {
                                                fontSize: '18px',
                                            },
                                            textAlign: "left",
                                            paddingY: "3px",
                                        }}
                                    >
                                    Showing companies  
                                    {/* <span style={{ color: '#1976d2' }}>({data?.response?.pagination?.total})</span> */}
                                    </Typography>

                                    <Box sx={{ display: { xs: 'flex', tablet: 'none' }, justifyContent: 'flex-end' }}>
                                    <div>
                                        {['left'].map((anchor) => (
                                            <React.Fragment key={anchor}>
                                                <IconButton
                                                    // variant='contained'
                                                    onClick={toggleDrawer(anchor, true)}
                                                    // fullWidth
                                                    sx={{
                                                        width: ' 32px',
                                                        height: ' 32px',
                                                        marginLeft: ' 8px',
                                                        backgroundColor: ' #B6DDFF',
                                                        display: ' flex',
                                                        alignItems: ' center',
                                                        justifyContent: ' center',
                                                        borderRadius: ' 50%',
                                                        ':focus': {outline:'unset'}
                                                    }}
                                                >
                                                    <TuneSharpIcon className="text-primary" />
                                                </IconButton>
                                                    <SwipeableDrawer
                                                        anchor={anchor}
                                                        open={state[anchor]}
                                                        onClose={toggleDrawer(anchor, false)}
                                                        onOpen={toggleDrawer(anchor, true)}
                                                    >
                                                    <Box
                                                        sx={{ width: 320 }}
                                                        role="presentation"
                                                        // onClick={toggleDrawer(anchor, false)}
                                                        // onKeyDown={toggleDrawer(anchor, false)}
                                                    >
                                                    <Box p={3}
                                                        sx={{
                                                            backgroundColor: '#FFFF',
                                                            width: '100%',
                                                            '@media (max-width: 600px)': {
                                                                width: '100%',
                                                            },
                                                            }}>
                                                            <form>
                                                                <Stack spacing={2}>
                                                                    <Stack
                                                                        spacing={2}
                                                                        direction={'row'}
                                                                        alignItems={'center'}
                                                                        justifyContent={'space-between'}
                                                                    >
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{
                                                                                fontWeight: 700,
                                                                                fontSize: '14px',
                                                                                '@media (max-width: 600px)': {
                                                                                    fontSize: '12px',
                                                                                },
                                                                            }}
                                                                        >
                                                                            All Filters
                                                                        </Typography>
                                                                        <Button
                                                                            size="small"
                                                                            variant="contained"
                                                                            endIcon={<ClearFilterIcon />}
                                                                            disableElevation
                                                                            sx={{
                                                                                backgroundColor: 'white',
                                                                                color: '#0275D8',
                                                                                borderRadius: '30px',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#e6f3ff',
                                                                                },
                                                                                '@media (max-width: 600px)': {
                                                                                    fontSize: '12px',
                                                                                },
                                                                            }}
                                                                            onClick={() => resetFilterForm()}
                                                                        >
                                                                            Clear Filter
                                                                        </Button>
                                                                    </Stack>
                                                                    <TextField
                                                                        label="Company Name"
                                                                        fullWidth
                                                                        size="small"
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        sx={{  backgroundColor: 'white' }}
                                                                        multiline
                                                                        value={employerName} 
                                                                        onChange={(e) => setEmployerName(e.target.value)} onBlur={(e) => setCompanySearchData({ ...companySearchData, employer_name: e.target.value })}
                                                                    />
                                                                    <TextField
                                                                        label="Company type"
                                                                        fullWidth
                                                                        size="small"
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        sx={{  backgroundColor: 'white' }}
                                                                        multiline
                                                                        value={employerType} 
                                                                        onChange={(e) => setEmployerType(e.target.value)} onBlur={(e) => setCompanySearchData({ ...companySearchData, company_type: e.target.value })}
                                                                    />
                                                                    <TextField
                                                                        label="Location"
                                                                        fullWidth
                                                                        size="small"
                                                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                                                        sx={{  backgroundColor: 'white' }}
                                                                        multiline
                                                                        value={employerLocation} 
                                                                        onChange={(e) => setEmployerLocation(e.target.value)} onBlur={(e) => setCompanySearchData({ ...companySearchData, map_location: e.target.value })}
                                                                    />
                                                                    <Select
                                                                        isClearable
                                                                        placeholder='Department'
                                                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                        className='react-select-container'
                                                                        classNamePrefix='react-select'
                                                                        options=
                                                                        {departments.map(item => {
                                                                            return (
                                                                                { label: item.name, value: item.id }
                                                                            )
                                                                        })
                                                                        }
                                                                        onChange={(e) => {
                                                                            const selectedValue = e ? { label: e.label, value: e.value } : null;
                                                                            setCompanySearchData({ ...companySearchData, department: e?.value || '' });
                                                                            setDepartment(e?.value || '');
                                                                            setDefaultSelectDepartment(selectedValue);
                                                                        }}                                        
                                                                        value={defaultSelectDepartment }
                                                                        menuPortalTarget={document.body}
                                                                    />
                                                                    <Select
                                                                        isClearable
                                                                        placeholder='Industry'
                                                                        styles={{ control: base => ({ ...base, height: '40px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                                                        className='react-select-container'
                                                                        classNamePrefix='react-select'
                                                                        options=
                                                                        {industries.map(item => {
                                                                            return (
                                                                                { label: item.name, value: item.id }
                                                                            )
                                                                        })
                                                                        }
                                                                        onChange={(e) => {
                                                                            const selectedValue = e ? { label: e.label, value: e.value } : null;
                                                                            setCompanySearchData({ ...companySearchData, industry: e?.value || '' });
                                                                            setIndustry(e?.value || '');
                                                                            setDefaultSelectIndustry(selectedValue);
                                                                        }} 
                                                                        value={defaultSelectIndustry}
                                                                        menuPortalTarget={document.body}
                                                                    />

                                                                    {/* <FormGroup>
                                                                        <FormControlLabel control={<Checkbox checked={topFeaturedCompanies} onChange={(e) => setTopFeaturedCompanies(e.target.checked)} />} label="Top Featured Companies" />
                                                                    </FormGroup> */}

                                                                    <FormControl>
                                                                        <FormLabel id="nature_of_business_label" sx={{ margin: '10px 0px !important', color: 'black', fontSize: 18, fontFamily: 'Epilogue', fontWeight: '700', wordWrap: 'break-word' }}>Nature of Business</FormLabel>
                                                                        <RadioGroup
                                                                            row
                                                                            aria-labelledby="nature_of_business_label"
                                                                            name="nature_of_business"
                                                                            value={businessType}
                                                                            onChange={handleBusinessTypeChange}
                                                                        >
                                                                            <FormControlLabel sx={{ color: 'black' }} value="b2b" control={<Radio />} label="B2B" />
                                                                            <FormControlLabel sx={{ color: 'black' }} value="b2c" control={<Radio />} label="B2C" />

                                                                        </RadioGroup>
                                                                    </FormControl>

                                                                    {/* <button type="button" class="btn btn-dark">Search By Benefit</button> */}
                                                                    <Button
                                                                        variant="contained"
                                                                        fullWidth
                                                                        startIcon={<SearchIcon />}
                                                                        sx={{
                                                                            backgroundColor: '#0079C1',
                                                                            color: 'white',
                                                                            fontSize: '12px',
                                                                            whiteSpace: "nowrap",
                                                                            textTransform: 'capitalize',
                                                                            '&:hover': {
                                                                                backgroundColor: '#0079C1',
                                                                            },
                                                                            paddingY: '12px',
                                                                        }}
                                                                        onClick={handleSearchButtonClick}
                                                                    >
                                                                        Search
                                                                    </Button>
                                                                </Stack>

                                                            </form>
                                                        </Box>
                                                    </Box>
                                                </SwipeableDrawer>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        {
                            isLoading && totalData.length === 0 ?
                                <div className="container">
                                    <GlobalLoader height='50vh' />
                                </div>
                                :
                                !isLoading && !isLazyLoading && totalData.length === 0 && count === 0 ?
                                <NoResultsFound />
                                :
                                <div>
                                    <Grid container spacing={3}>
                                        {
                                            totalData?.map((item, index) => {
                                                return (
                                                    <Grid item key={index} xs={12} sm={6} tablet={6} md={4} lg={4} xl={3} >
                                                        <Link to={`/employer/profile/${item.slug}`} >
                                                            <Card sx={{ margin: '20px 10px', height: '120px', display: 'flex', alignItems: 'center' }} key={index}>
                                                                <div className='text-c' style={{ width: '100%' }}>
                                                                    {
                                                                        item.profile_pic_s3 ?
                                                                            <img style={{ maxWidth: '200px', maxHeight: '90px' }} src={item.profile_pic_s3} alt='Employer avatar' />
                                                                            :
                                                                            <img style={{ maxWidth: '200px', maxHeight: '90px' }} src='/images/company_placeholder_icon.png' alt='Employer avatar' />
                                                                    }
                                                                </div>
                                                            </Card>
                                                        </Link>
                                                        <div style={{ textAlign: 'left', color: '#0275D8', fontSize: 15, fontFamily: 'Epilogue', fontWeight: '600', wordWrap: 'break-word', paddingLeft: '10px' }}>
                                                            <Link to={`/employer/profile/${item.slug}`} >
                                                            <Typography style={{fontSize: 16, fontFamily: 'Epilogue', fontWeight: '700' }}>
                                                                {item.employer_name} 
                                                            </Typography>
                                                            <Typography style={{fontSize: 14, fontFamily: 'Epilogue', fontWeight: '500' }}>
                                                                Job opening [{item.published_jobs_count}]
                                                            </Typography>
                                                            </Link>
                                                        </div>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </div>
                        }

                    </Box>

                    <Box sx={{minHeight: "150px", marginTop: "-70px"}}>
                        { isAtBottom && isLazyLoading ? (
                            <Box sx={{paddingBottom: "50px"}}>
                                <GlobalLoader />
                            </Box>
                        )
                        :
                        ""
                        }
                    </Box>

                </Grid>
            </Grid>
            {/* </div> */}
        </ThemeProvider>
        </Box>
    )
}