import Cookies from "js-cookie";
const bearerToken = Cookies.get("token");

export const COMMON_FETCH_HEADER = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const PROTECTED_FETCH_HEADER = (token = bearerToken) => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
};

export const PROTECTED_FETCH_HEADER_FORMDATA = (token = bearerToken) => {
  return {
    Authorization: "Bearer " + token,
  };
};
