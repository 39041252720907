import React from "react";
import {
  PROTECTED_FETCH_HEADER,
  PROTECTED_FETCH_HEADER_FORMDATA,
} from "../../../../../../../src/constants/apiheader";
import {
  DR_MESSAGE_GET_API,
  DR_MESSAGE_READ_API,
  DR_MESSAGE_SEND_API,
  DR_MESSAGE_SUMMARY_API,
  GET_DIRECT_SCOUTING_STAT_API,
} from "../../../../../../../src/constants/apipath";

import { createContext, useContext, useEffect, useReducer } from "react";
import { useAlert } from "../../../../../context/AlertContext";
import { UserContext } from "../../../../../context/UserContext";
import Cookies from "js-cookie";

const InboxContext = createContext();

export function useDrInbox() {
  const context = useContext(InboxContext);
  return context;
}

const initState = {
  messageBodyRef: null, // ref of the message box to detect scroll
  statListBodyRef: null, // ref of stat list to detect scroll
  currentChatId: null, // current chatid user chatting
  chatIdChanging: false, // in chatid tranistion time this field is used to disable some action like triggering event while scrolling
  currentChatParticipants: [], // caht participants info
  messageGetAction: null, // "prev" || "next"
  messages: {}, // messages to show
  messageLoading: false, // used to detect when to fetch messages from server based on currentChatId, messageGetAction and messageId from messages
  loading: false, // is page loading
  sendingMessage: false, // sending message loader
  data: [], // stats
  statCurrentPage: { page: 1 }, // used for stat pagination
  statPagination: {}, // pagination info  of stat from server
  statLoading: false, // stat loading bit
};

function reducer(state, action) {
  switch (action.type) {
    case "statListBodyRef":
      return { ...state, statListBodyRef: action.statListBodyRef };
    case "setMessageBodyRef":
      return { ...state, messageBodyRef: action.messageBodyRef };
    case "getStats": {
      if (state.statCurrentPage?.page == 1) {
        return {
          ...state,
          data: action.data,
          statPagination: action.pagination,
        };
      }
      let data = state.data;
      data.stats = [...data.stats, ...action.data?.stats];
      return {
        ...state,
        data: data,
        statPagination: action.pagination,
      };
    }

    case "statPaginateInit":
      return { ...state, statLoading: true, statCurrentPage: { page: 1 } };
    case "statPaginate": {
      // console.log(state.statPagination?.last_page);
      if (state.statPagination?.last_page > state.statCurrentPage?.page) {
        return {
          ...state,
          statLoading: true,
          statCurrentPage: { page: state.statCurrentPage?.page + 1 },
        };
      } else {
        return state;
      }
    }
    case "getInitMessage": {
      if (state.currentChatId != null) {
        return { ...state, messageGetAction: null, messageLoading: true };
      }
      return state;
    }
    case "getNextMessage": {
      if (
        state.currentChatId != null &&
        state.messages[state.currentChatId]?.length > 0
      ) {
        return { ...state, messageLoading: true, messageGetAction: "next" };
      }
      return state;
    }
    case "getPrevMessage": {
      if (
        state.currentChatId != null &&
        state.messages[state.currentChatId]?.length > 0
      ) {
        return { ...state, messageLoading: true, messageGetAction: "prev" };
      }
      return state;
    }
    case "newChatOpen": {
      let messages = state.messages;
      if (messages[action.currentChatId] == undefined) {
        messages[action.currentChatId] = [];
      }
      return {
        ...state,
        messages,
        currentChatId: action.currentChatId,
        currentChatParticipants: action.currentChatParticipants,
        chatIdChanging: true,
      };
    }
    case "chatIdChanged": {
      return { ...state, chatIdChanging: false };
    }
    case "nextMessages": {
      let messages = state.messages;
      if (
        messages[state.currentChatId]?.length > 0 &&
        action.data?.length > 0 &&
        messages[state.currentChatId].filter(
          (item) => item.id == action.data[0]?.id
        ).length > 0
      ) {
        return state;
      }

      messages[state.currentChatId] = [
        ...messages[state.currentChatId],
        ...action.data,
      ];
      return { ...state, messages };
    }
    case "prevMessages": {
      let messages = state.messages;
      messages[state.currentChatId] = [
        ...action.data,
        ...messages[state.currentChatId],
      ];
      return { ...state, messages };
    }
    case "loading":
      return { ...state, loading: true };
    case "statLoading":
      return { ...state, statLoading: true };
    case "statLoaded":
      return { ...state, statLoading: false };
    case "messageLoading":
      return { ...state, messageLoading: true };
    case "sendingMessage":
      return { ...state, sendingMessage: true };
    case "messageSent":
      return { ...state, sendingMessage: false };
    case "error":
      return {
        ...state,
        loading: false,
        messageLoading: false,
        sendingMessage: false,
        statLoading: false,
      };
    case "loaded":
      return { ...state, loading: false };
    case "messageLoaded":
      return { ...state, messageLoading: false };

    default:
      break;
  }
}

const formatData = (data, ownUserId) => {
  return {
    totalScouting: data.stat?.total,
    chats: data.chats?.map((item) => ({
      postId: item.job_post_id,
      jobTitle: item.job_title,
      chatId: item.id,
      lastMessageState: item.state,
      lastMessageContent: item.content,
      lastMessageAttachment: item.attachment,
      lastMessageSenderId: item.user_id,
      partnerName: item.name,
      partnerPic: item.pic,
      isLastMessageSenderMe: item.user_id == ownUserId,
      readStatus: item.user_id == ownUserId || item.state == 2,
    })),
  };
};

function DrInboxContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initState);
  const { userData } = useContext(UserContext);
  const alertContext = useAlert();

  // console.log(userData);

  const getStats = async (token) => {
    dispatch({ type: "statLoading" });

    try {
      const response = await fetch(
        DR_MESSAGE_SUMMARY_API + `?page=${state.statCurrentPage?.page}`,
        {
          method: "POST",
          headers: PROTECTED_FETCH_HEADER(token),
        }
      );
      const responseJson = await response.json();
      if (responseJson && responseJson.success) {
        dispatch({
          type: "getStats",
          data: formatData(responseJson.response.data, userData?.id),
          pagination: responseJson.response.pagination,
        });

        if (
          state.statListBodyRef?.current &&
          state.statCurrentPage?.page == 1
        ) {
          setTimeout(() => {
            state.statListBodyRef.current.scroll({
              top: 0,
              behavior: "smooth",
            });
          }, 300);
        }
      } else {
        dispatch({ type: "error" });
      }
    } catch (error) {
      //   console.error("Error fetching messages:", error);
      dispatch({ type: "error" });
    } finally {
      dispatch({ type: "statLoaded" });
    }
  };

  const getMessages = async (messageId = null, action = null) => {
    let bearerToken = Cookies.get("token");
    try {
      const response = await fetch(
        DR_MESSAGE_GET_API +
          `?chatId=${state.currentChatId}${
            messageId ? "&messageId=" + messageId : ""
          }${action ? "&action=" + action : ""}`,
        {
          method: "GET",
          headers: PROTECTED_FETCH_HEADER(bearerToken),
        }
      );
      const responseJson = await response.json();
      if (responseJson && responseJson.success) {
        let messages = responseJson.response.data?.messages;

        if (action == "prev") {
          dispatch({
            type: "prevMessages",
            data: messages,
          });
        } else {
          dispatch({
            type: "nextMessages",
            data: messages,
          });
        }

        let unreadMessageIds = messages
          .filter((item) => item.state < 2 && item.user_id != userData?.id)
          .map((item) => item.id);

        if (unreadMessageIds.length > 0) {
          readMessages(unreadMessageIds);
        }

        if (
          state.messageBodyRef?.current &&
          (action == null || action == "next")
        ) {
          setTimeout(() => {
            state.messageBodyRef.current.scroll({
              top: state.messageBodyRef.current.scrollHeight,
              behavior: "smooth",
            });
          }, 300);
        }
      } else {
        dispatch({ type: "error" });
      }
    } catch (error) {
      //   console.error("Error fetching messages:", error);
      dispatch({ type: "error" });
    } finally {
      dispatch({ type: "messageLoaded" });
    }
  };

  const readMessages = async (ids) => {
    let bearerToken = Cookies.get("token");
    try {
      const response = await fetch(DR_MESSAGE_READ_API, {
        method: "POST",
        headers: PROTECTED_FETCH_HEADER(bearerToken),
        body: JSON.stringify({ chatId: state.currentChatId, messageIds: ids }),
      });

      return response;
    } catch (error) {}
  };

  useEffect(() => {
    setTimeout(dispatch({ type: "chatIdChanged" }, 200));
  }, [state.chatIdChanging]);

  useEffect(() => {
    if (state.currentChatId && state.messageLoading) {
      if (state.messageGetAction == null) {
        getMessages();
      }
      if (state.messageGetAction == "next") {
        getMessages(
          state.messages[state.currentChatId][
            state.messages[state.currentChatId].length - 1
          ].id,
          "next"
        );
      } else if (state.messageGetAction == "prev") {
        getMessages(state.messages[state.currentChatId][0].id, "prev");
      }
    }
  }, [state.messageLoading]);

  useEffect(() => {
    if (
      state.messages[state.currentChatId] &&
      state.messages[state.currentChatId].length > 0
    ) {
      // console.log("here");
      dispatch({ type: "getNextMessage" });
    } else {
      dispatch({ type: "getInitMessage" });
    }

    const refreshMessageInterval = setInterval(() => {
      dispatch({ type: "getNextMessage" });
    }, 10000);

    return () => {
      clearInterval(refreshMessageInterval);
    };
  }, [state.currentChatId]);

  const sendMessage = async (formdata) => {
    let bearerToken = Cookies.get("token");

    dispatch({ type: "sendingMessage" });
    try {
      const response = await fetch(DR_MESSAGE_SEND_API, {
        method: "POST",
        headers: PROTECTED_FETCH_HEADER_FORMDATA(bearerToken),
        body: formdata,
      });
      const responseJson = await response.json();
      if (responseJson && responseJson.success) {
        // alertContext.setResponseMessage(responseJson.response.message);
        // alertContext.setOpenAlert(true);

        getMessages(
          state.messages[state.currentChatId][
            state.messages[state.currentChatId].length - 1
          ].id,
          "next"
        );
      } else {
        alertContext.setResponseMessage(
          responseJson.response.errors[0].message[0]
        );
        alertContext.setOpenErrorAlert(true);
      }
      return responseJson;
    } catch (error) {
      // console.error("Error get message:", error);
      dispatch({ type: "error" });
    } finally {
      dispatch({ type: "messageSent" });
    }
  };

  useEffect(() => {
    const refreshStatInterval = setInterval(() => {
      dispatch({ type: "statPaginateInit" });
    }, 30000);

    return () => {
      clearInterval(refreshStatInterval);
    };
  }, []);

  useEffect(() => {
    if (userData?.id) {
      let bearerToken = Cookies.get("token");
      getStats(bearerToken);
    }
  }, [userData, state.statCurrentPage]);

  return (
    <InboxContext.Provider value={{ state, dispatch, sendMessage }}>
      {props.children}
    </InboxContext.Provider>
  );
}

export default DrInboxContextProvider;
