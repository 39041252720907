import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import styles from "./MessageBox.module.css";
import { useDrInbox } from "../contexts/DrInboxContextProvider";
import parse from "react-html-parser";

function StatList({ setOpenDrawer }) {
  const { state, dispatch } = useDrInbox();

  const statListBodyRef = useRef();

  useEffect(() => {
    dispatch({ type: "statListBodyRef", statListBodyRef: statListBodyRef });
  }, [statListBodyRef]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    // console.log(scrollTop + clientHeight, scrollHeight);
    if (
      scrollTop + clientHeight + 20 >= scrollHeight &&
      !state.statLoading &&
      !state.loading
    ) {
      dispatch({ type: "statPaginate" });
    }
  };

  const attachmentFileName = (file) => {
    let fileA = file.split("/");
    return fileA[fileA.length - 1];
  };

  return (
    <Box
      sx={{
        boxShadow: "2px 0 2px -1px #888",
        height: "100%",
        paddingBottom: "20px",
        backgroundColor: "var(--app-appbar-bg-color)",
      }}
    >
      <Box
        sx={{
          boxShadow: "-3px 0px 4px 0px #757575;",
          zIndex: 8,
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "30px",
        }}
      >
        <Typography className="app-heading-primary-lg">Scouting</Typography>
        {state?.data?.totalScouting > 0 ? (
          <Chip
            label={state?.data?.totalScouting}
            sx={{
              background: "var(--app-button-bg-color-light-primary)",
              color: "var(--app-text-color-primary)",
              fontWeight: 700,
            }}
          />
        ) : null}
      </Box>
      <Box
        sx={{ height: "calc(100% - 51px)", overflowY: "auto" }}
        ref={statListBodyRef}
        onScroll={handleScroll}
      >
        {state?.data?.chats?.map((item) => (
          <Box
            key={item.chatId}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              padding: "10px",
              cursor: "pointer",
              backgroundColor:
                item.chatId == state.currentChatId ? "#bddfff" : "",
            }}
            onClick={() => {
              setOpenDrawer(true);
              if (item.chatId != state.currentChatId) {
                dispatch({
                  type: "newChatOpen",
                  currentChatId: item.chatId,
                  currentChatParticipants: [{
                    name: item.partnerName,
                    pic: item.partnerPic,
                  }],
                });
              } else {
                dispatch({ type: "getNextMessage" });
              }
            }}
          >
            <img
              src={
                item.partnerPic
                  ? item.partnerPic
                  : "/assets/images/candidate-default.png"
              }
              style={{ borderRadius: "50%", width: "50px", height: "50px" }}
            />
            <Box>
              <Box sx={{ lineHeight: 1.2 }}>
                <Box className="app-heading-primary-md">{item.jobTitle}</Box>
                <Box className="app-text-secondary-xs">
                  ( {item.partnerName} )
                </Box>
              </Box>
              <Box
                className="app-text-secondary-sm"
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  "-webkit-box-orient": "vertical",
                  "-webkit-line-clamp": '1',
                  color: !item.readStatus
                    ? "#1a1a1a !important"
                    : "#9f9f9f!important",
                  fontWeight: !item.readStatus ? "500" : "normal",
                }}
              >
                {item.lastMessageContent
                  ? parse(item.lastMessageContent)
                  : attachmentFileName(item.lastMessageAttachment)}
              </Box>
            </Box>
          </Box>
        ))}
        {state.statLoading ? (
          <span className={styles.loader}></span>
        ) : (
          <span></span>
        )}
      </Box>
    </Box>
  );
}

export default StatList;
