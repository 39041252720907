import React, { useContext, useState, useEffect, useRef } from 'react'
import { Modal, Box, Typography, Grid, InputLabel, TextField, MenuItem, Stack, IconButton, Button, CircularProgress, Container, InputAdornment } from '@mui/material'
import { CandidateContext } from '../../../context/CandidateContext'
import { applyToJob, getCheckReference } from '../../../../fetchAPI'
import { LoadingButton } from '@mui/lab'
import SuccessAlert from '../../Alerts/SuccessAlert'
import { UserContext } from '../../../context/UserContext'
import { Link } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAlert } from '../../../context/AlertContext'
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RequiredAsterisk from '../../CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk'
import { handleKeyDown, validatePhoneNumber } from '../../../helpers/helperFunctions'
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});


function BpRadio(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}

            sx={{
                '& .MuiTypography-root': {
                    fontSize: '28px !important',
                },
            }}
            {...props}
        />
    );
}


function UploadIcon() {
    return (
        <img
            alt="upload icon"
            src="/v2/icons/uploadIconSmall.png"
            height={'40px'}
        />
    )
}

function CrossIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#FFDCDC" />
            <path d="M5.93246 9.69582C5.52683 10.1014 5.1223 10.506 4.71668 10.9116C4.46782 11.1605 4.46563 11.4784 4.69914 11.6987C4.78058 11.7769 4.88599 11.8254 4.99837 11.8364C5.11075 11.8473 5.22354 11.8201 5.31854 11.759C5.38703 11.7118 5.4509 11.6582 5.50929 11.599L7.85097 9.25731C8.02309 9.08519 7.97704 9.08519 8.15574 9.26169C8.94726 10.0532 9.73878 10.8447 10.5303 11.6363C10.789 11.895 11.1058 11.9048 11.3295 11.6702C11.4083 11.5895 11.4576 11.4846 11.4695 11.3725C11.4815 11.2603 11.4553 11.1474 11.3953 11.0519C11.349 10.9827 11.2958 10.9184 11.2363 10.8601C10.4448 10.0686 9.65217 9.27594 8.86065 8.48442C8.76308 8.38685 8.76198 8.33752 8.86065 8.24105C9.65217 7.44953 10.4437 6.658 11.2363 5.86539C11.2925 5.81139 11.3432 5.75189 11.3876 5.68779C11.4515 5.58862 11.4796 5.47054 11.4671 5.3532C11.4546 5.23587 11.4022 5.12636 11.3188 5.04292C11.2353 4.95948 11.1258 4.90715 11.0085 4.89463C10.8912 4.8821 10.7731 4.91015 10.6739 4.9741C10.6053 5.0211 10.5417 5.07511 10.4843 5.13526L8.15354 7.46597C7.97375 7.64576 8.02418 7.64576 7.83891 7.46049C7.06273 6.68432 6.28583 5.90741 5.50819 5.12978C5.45419 5.07354 5.3947 5.02286 5.3306 4.97849C5.23129 4.91236 5.11215 4.88267 4.99343 4.89445C4.87471 4.90624 4.76373 4.95878 4.67937 5.04314C4.59501 5.1275 4.54247 5.23848 4.53068 5.3572C4.5189 5.47592 4.54859 5.59506 4.61472 5.69436C4.6601 5.75766 4.7111 5.81673 4.7671 5.87087C5.55863 6.66239 6.35124 7.45501 7.14277 8.24653C7.23485 8.33861 7.23595 8.38795 7.14277 8.47894C6.74262 8.88566 6.33699 9.29129 5.93246 9.69582Z" fill="#DB1616" />
        </svg>

    )
}

function ProfileStrengthLowMessage({ props }) {
    return (
        <DialogContent dividers={'paper'} p={5}>
            <Container maxWidth="sm">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                    <IconButton onClick={() => props.handleClose()}>
                        <CrossIcon />
                    </IconButton>
                </Box>
                <Stack spacing={{ md: 5, xs: 2 }}>
                    <center>
                        <img style={{ width: '220px', height: 'auto' }} src='/images/profile-strenght-graphics.png' />
                    </center>
                    <Typography
                        variant='h4'
                        sx={{
                            fontSize: { md: '20px', xs: '16px' },
                            lineHeight: '32px',
                            fontWeight: '700',
                            textAlign: 'center'
                        }}
                    >
                        Due to your profile strength being below {process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY}, <br /> you are not eligible to apply for this job. <br /> To apply for this job, please update your profile.
                    </Typography>

                    <Stack spacing={2} direction="row" sx={{ padding: { md: 3, xs: 0 }, justifyContent: 'space-between' }}>
                        <Button
                            size='large'
                            variant='outlined'
                            fullWidth
                            onClick={() => props.handleClose()}
                            sx={{
                                textTransform: 'capitalize',
                                fontSize: { md: '15px', xs: '10px' }
                            }}
                        >
                            <Link to="#" style={{ color: '#000' }} onClick={() => props.handleClose()}>Cancel</Link>
                        </Button>
                        <Button
                            size='large'
                            variant='contained'
                            fullWidth
                            onClick={() => props.handleClose()}
                            sx={{
                                textTransform: 'capitalize',
                                fontSize: { md: '15px', xs: '10px' }
                            }}
                        >
                            <Link to={`/candidate-register/update?job_id=${props.jobInfo.post_id}`} style={{ color: '#fff' }} onClick={() => props.handleClose()}>Update Profile</Link>
                        </Button>
                    </Stack>
                </Stack>
            </Container>
        </DialogContent>
    )
}

function NonLoggedInMessage({ props }) {
    return (
        <DialogContent dividers={'paper'} sx={{ padding: "0px", margin: "0px" }}>
            <>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                    <IconButton onClick={() => props.handleClose()}>
                        <CrossIcon />
                    </IconButton>
                </Box>
                <Stack spacing={6} alignItems={'center'} sx={{ margin: { xs: "20px", md: "40px" } }}>
                    <Typography
                        variant='h4'
                        sx={{
                            fontSize: { md: '24px', xs: '15px' },
                            fontWeight: 700,
                            textAlign: 'center'
                        }}
                    >
                        You are applying for the position of <br /> <span style={{ color: '#0275D8' }}>"{props.jobInfo.job_title}"</span>
                    </Typography>

                    {/* New Register */}
                    <Box>
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: { md: '20px', xs: '12px' },
                                fontWeight: '700',
                                color: '#1D1E1B'
                            }}
                        >
                            If you are a new user, please
                        </Typography>

                        <Link className="text-light" to={`/candidate-register?job_id=${props.jobInfo?.post_id}`}>
                            <Button
                                variant='contained'
                                sx={{
                                    marginTop: 2,
                                    padding: '15px',
                                    backgroundColor: '#0079C1',
                                    textTransform: 'capitalize',
                                    fontWeight: 700
                                }}
                                fullWidth
                                className='ga_candidate_register_button'
                            >
                                Click Here to Register
                            </Button>
                        </Link>
                    </Box>

                    {/* Already have an account */}
                    <Box>
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: { md: '20px', xs: '12px' },
                                fontWeight: '700',
                                color: '#1D1E1B'
                            }}
                        >
                            Already Have an Account?
                        </Typography>

                        <Link to={`/login?is_cxo=${props?.jobInfo?.is_cxo}&job_id=${props.jobInfo?.post_id}`}>
                            <Button
                                className='js_login_button_click'
                                variant='contained'
                                fullWidth
                                sx={{
                                    marginTop: 2,
                                    padding: '15px',
                                    backgroundColor: '#00457C',
                                    textTransform: 'capitalize',
                                    fontWeight: 700
                                }}
                            >
                                Click Here to Login
                            </Button>
                        </Link>
                    </Box>
                </Stack>
            </>
        </DialogContent>

    )
}

const useStyles = makeStyles({
    input: {
        borderLeft: '1px solid #C4C4C4',
        paddingLeft: "14px",
        marginLeft: "7px",
    },
});

function ApplyJobForm({ props }) {
    const { candidateData, settingCandidateData, profileStrength, candidateDataLoading } = useContext(CandidateContext)
    const { setApplyAttemptJobInfo, setRedirectionLink } = useContext(UserContext)
    const [applyLoader, setApplyLoader] = useState(false)
    const [customAnswers, setCustomAnswers] = useState([])
    const [applyResume, setApplyResume] = useState('')
    const [coverLetter, setCoverLetter] = useState('')
    const [applyData, setApplyData] = useState([])
    const [loader, setLoader] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [isError, setIsError] = useState(false)
    const uploadRef = useRef('')
    const classes = useStyles();

    const [country, setCountry] = useState("bd");
    const [phoneNumber, setPhoneNumber] = useState("")

    // Reference States
    const [referenceCheck, setReferenceCheck] = useState(0)
    const [referenceData, setReferenceData] = useState({
        reference_name: '',
        reference_designation: '',
        reference_relation: '',
        reference_email: '',
        reference_organization: '',
        reference_contact: '',
        dial_code: '',
    })

    function fileName(link) {
        if (link) {
            let resumeReverse = link.split('').reverse().join('');
            let reverseArray = resumeReverse.split("/")
            let filename = reverseArray[0].split('').reverse().join('')
            return filename
        }
    }

    const handlePhoneChange = (value, countryData) => {
        if (country !== countryData.countryCode) {
            setReferenceData({ ...referenceData, "reference_contact": "" })
            setCountry(countryData.countryCode);
        }

        if (countryData.countryCode === "bd") {
            setReferenceData({ ...referenceData, "reference_contact": value.replace(`${countryData.dialCode}`, "0"), "dial_code": countryData.dialCode })
        } else {
            setReferenceData({ ...referenceData, "reference_contact": value.replace(`${countryData.dialCode}`, ""), "dial_code": countryData.dialCode })
        }

        setPhoneNumber(value)
    }

    const handleCustomAnswerChange = (id, index) => (event) => {
        let answers = [...customAnswers]
        answers[index] = { id: id, answer: parseInt(event.target.value) }
        setCustomAnswers(answers)

    }

    const handleReferenceDataChange = (name) => (event) => {
        setReferenceData({ ...referenceData, [name]: event.target.value })
    }

    const settingReferenceCheckOnInitalLoad = () => {
        getCheckReference().then((response) => {
            if (response && response.success) {
                setReferenceCheck(response.response.data)
                setLoader(false)
            } else {
                alertContext.setResponseMessage(response.errors[0].message);
                alertContext.setOpenErrorAlert(true);
                setLoader(false)
            }
        })
    }

    useEffect(() => {
        setLoader(true)
        settingReferenceCheckOnInitalLoad()
    }, [])

    useEffect(() => {
        let data = new FormData()
        data.append('candidate_id', candidateData?.id)
        data.append('resume', applyResume)
        data.append('cover_letter', coverLetter)
        data.append('reference_name', referenceData.reference_name)
        data.append('reference_designation', referenceData.reference_designation)
        data.append('reference_relation', referenceData.reference_relation)
        data.append('reference_email', referenceData.reference_email)
        data.append('reference_organization', referenceData.reference_organization)
        data.append('reference_contact', referenceData.reference_contact)
        data.append('dial_code', referenceData.dial_code)
        data.append('answer', JSON.stringify(customAnswers.filter(item => item != null)))
        setApplyData(data)

        // if (data.get('resume') != "") {
        // 	setIsValid(true)
        // } else {
        // 	setIsValid(false)
        // }

    }, [candidateData, coverLetter, applyResume, customAnswers, referenceData])

    const applyJob = () => {
        if (country === "bd" && referenceData?.reference_contact !== "" && referenceData?.reference_contact !== "0" && !validatePhoneNumber(referenceData?.reference_contact)) {
            alertContext.setOpenErrorAlert(true);
            alertContext.setResponseMessage("Please enter a valid phone number!");
            return;
        }

        setApplyLoader(true)
        applyToJob(props.jobInfo?.post_id, applyData)
            .then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.handleClose()
                    settingCandidateData()
                    setApplyResume('')
                    setCoverLetter('')

                    //gtag code for successful job application - don't remove
                    window.dataLayer.push({
                        'event': 'successful_application',
                        'regMethod': 'from_search_job_and_job_details_page'
                    });
                    //gtag code ended
                } else {
                    alertContext.setResponseMessage(response.errors[0].message);
                    alertContext.setOpenErrorAlert(true);
                }
            }).finally(() => setApplyLoader(false))
    }

    const cancelApply = () => {
        setApplyResume('')
        setApplyData('')
        props.handleClose()
    }

    const styleForNonRegisterUser = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (props.open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.open]);

    const alertContext = useAlert();

    return (

        <>
            <DialogTitle id="scroll-dialog-title">
                <Typography
                    variant='h4'
                    sx={{
                        fontSize: { md: '20px', xs: '13px' },
                        fontWeight: 700,
                        margin: 1
                    }}
                >
                    Applying to <span style={{ color: '#0275D8' }}>{props.jobInfo.job_title}</span>
                </Typography>
            </DialogTitle>
            <form onSubmit={(e) => [e.preventDefault(), applyJob()]} className='g-job-apply-form'>
                <DialogContent dividers={'paper'}>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </Box>
                            :
                            <DialogContentText
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                            >
                                <Box p={2}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} xs={12}>
                                            <TextField
                                                required
                                                select
                                                name='skill'
                                                variant='outlined'
                                                placeholder='Select Skills'
                                                defaultValue={0}
                                                fullWidth
                                                onChange={(e) => setApplyResume(e.target.value)}
                                            >
                                                <MenuItem value={0}>
                                                    Select Resume
                                                </MenuItem>
                                                {candidateData.candidate_resume?.map((resume) => {
                                                    return (
                                                        <MenuItem value={resume.id}>
                                                            {fileName(resume.resume_link)}
                                                        </MenuItem>
                                                    )
                                                }
                                                )}
                                            </TextField>
                                        </Grid>
                                        <Grid item lg={6} xs={12}>
                                            <Stack spacing={0}>
                                                <input type="file" name="footer_background_image" id='bg-image-input' ref={uploadRef} hidden onChange={(e) => setApplyResume(e.target.files[0])} />
                                                <Box sx={{ border: '1px dashed #0275D8', borderRadius: '6px', backgroundColor: '#EAF5FF' }}>
                                                    <Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'} sx={{ height: '53px' }} >
                                                        <Typography variant="h4" sx={{ fontSize: { md: '16px', xs: '12px' }, fontWeight: '700', color: '#0275D8', padding: '10px', width: { xs: "200px", sm: "400px", md: "700px", lg: "500px" }, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", textAlign: "left"}}>
                                                            {applyResume.name ? applyResume.name : 'Upload Your Resume'}
                                                        </Typography>
                                                        <IconButton aria-label="upload Button" onClick={() => uploadRef.current.click()}>
                                                            <UploadIcon />
                                                        </IconButton>
                                                    </Stack>
                                                </Box>
                                                <Typography variant="caption" sx={{ color: '#72737C', marginTop: 1 }}>Supported Formats: doc, docx, pdf upto 10 MB</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    {/* Cover letter section */}

                                    {/* <Stack sx={{ marginTop: 2 }}>
                                        <Typography variant="h3" sx={{ fontSize: '16px', fontWeight: '700', color: '#1D1E1B' }}>Cover Letter</Typography>
                                        <Typography variant="span" sx={{ color: '##72737C', marginTop: 2, fontWeight: '500', fontSize: '13px' }}>A good cover letter can greatly increase your chances of standing out to recruiters.</Typography>

                                        <TextField
                                            type='text'
                                            name='cover_letter'
                                            variant='outlined'
                                            placeholder='Write Your Cover Letter'
                                            fullWidth
                                            multiline
                                            rows={3}
                                            onChange={(e) => (setCoverLetter(e.target.value))}
                                            sx={{ marginTop: 1 }}
                                        />
                                    </Stack> */}

                                    <Stack sx={{ marginTop: 4 }}>
                                        {/* <Typography variant="h3" sx={{ fontSize: '16px', fontWeight: '700', color: '#0275D8' }}>Custom Questions</Typography>
                                        {
                                            props.jobInfo?.questions?.map((item, index) => {
                                                return (
                                                    <Box sx={{ marginTop: 1 }} key={index}>
                                                        <InputLabel sx={{ color: '##72737C', marginTop: 2, fontWeight: '500', fontSize: '13px' }}>{item?.question}</InputLabel>
                                                        <TextField
                                                            type='text'
                                                            name='footer'
                                                            variant='outlined'
                                                            fullWidth
                                                            onChange={handleCustomAnswerChange('answer', item.id, index)}
                                                        />
                                                    </Box>
                                                )
                                            })
                                        } */}

                                        {
                                            props.jobInfo?.questions && props.jobInfo.questions.length > 0 ?
                                                <Typography variant="h3" sx={{ fontSize: '16px', fontWeight: '700', color: '#0275D8' }}>Custom Questions</Typography>
                                                :
                                                null
                                        }
                                        {
                                            props.jobInfo?.questions?.map((item, index) => {
                                                return (
                                                    <Box sx={{ marginTop: 1 }} key={index}>
                                                        <hr />
                                                        <InputLabel sx={{ color: '#72737C', marginBottom: '10px !important', fontWeight: '600', fontSize: '14px', fontFamily: 'Epilogue' }}>{item?.question}</InputLabel>

                                                        <FormControl>
                                                            <FormLabel id="job-post-custom-question-options">Please choose an option</FormLabel>
                                                            <RadioGroup
                                                                aria-labelledby="job-post-custom-question-options"
                                                                name="job-post-custom-question-options"
                                                                onChange={handleCustomAnswerChange(item.id, index)}
                                                            >
                                                                {
                                                                    item?.options?.map((oItem, oIndex) => {
                                                                        return <FormControlLabel sx={{
                                                                            fontFamily: 'Poppins', '& .MuiTypography-root': {
                                                                                fontSize: '14px !important',
                                                                            }
                                                                        }} value={oItem.id} control={<BpRadio />} label={oItem.option} />
                                                                    })
                                                                }
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Box>
                                                )
                                            })
                                        }

                                    </Stack>




                                    {
                                        referenceCheck == 0 ?
                                            <Stack sx={{ marginTop: 4 }}>
                                                <Typography variant="h3" sx={{ fontSize: '16px', fontWeight: '700', color: '#0275D8' }}>Add a reference</Typography>
                                                <Typography variant="span" sx={{ color: '##72737C', marginTop: 2, fontWeight: '500', fontSize: '13px' }}>Please add a reference in your profile to ensure the highest possibility of getting noticed</Typography>

                                                <Grid container spacing={2} mt={2}>
                                                    <Grid item md={6} xs={12}>
                                                        <InputLabel sx={{ color: '##72737C', marginTop: 2, fontWeight: '500', fontSize: '13px' }}>Name <RequiredAsterisk /> </InputLabel>
                                                        <TextField
                                                            required
                                                            type='text'
                                                            variant='outlined'
                                                            fullWidth
                                                            onChange={handleReferenceDataChange('reference_name')}
                                                            placeholder='Enter name'
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <InputLabel sx={{ color: '##72737C', marginTop: 2, fontWeight: '500', fontSize: '13px' }}>Mobile Number</InputLabel>
                                                        <PhoneInput
                                                            country={country}
                                                            value={phoneNumber || ""}
                                                            onChange={handlePhoneChange}
                                                            inputProps={{
                                                                name: 'mobile_number',
                                                                required: true,
                                                                // autoFocus: true,
                                                            }}
                                                            countryCodeEditable={false}
                                                            enableSearch={true}
                                                            containerClass={classes.outlinedInput}
                                                            inputStyle={{
                                                                width: `calc(100% - 20px)`,
                                                                border: '1px solid #C4C4C4',
                                                                borderRadius: '4px',
                                                                fontSize: '16px',
                                                                fontWeight: 400,
                                                                color: 'rgba(0, 0, 0, 0.87)',
                                                                outline: 'none',
                                                                paddingTop: '27px',
                                                                paddingBottom: "27px",
                                                                marginLeft: "20px",
                                                            }}
                                                            searchStyle={{ width: "90%" }}
                                                            dropdownStyle={{ width: `${window.innerWidth > 560 ? "600%" : "400%"}` }}
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <InputLabel sx={{ color: '##72737C', marginTop: 2, fontWeight: '500', fontSize: '13px' }}>Designation <RequiredAsterisk /></InputLabel>
                                                        <TextField
                                                            required
                                                            type='text'
                                                            variant='outlined'
                                                            fullWidth
                                                            onChange={handleReferenceDataChange('reference_designation')}
                                                            placeholder='Enter designation'
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <InputLabel sx={{ color: '##72737C', marginTop: 2, fontWeight: '500', fontSize: '13px' }}>Email Address <RequiredAsterisk /></InputLabel>
                                                        <TextField
                                                            required
                                                            type='email'
                                                            variant='outlined'
                                                            fullWidth
                                                            onChange={handleReferenceDataChange('reference_email')}
                                                            placeholder='Enter email'
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <InputLabel sx={{ color: '##72737C', marginTop: 2, fontWeight: '500', fontSize: '13px' }}>Relation</InputLabel>
                                                        <TextField
                                                            type='text'
                                                            variant='outlined'
                                                            fullWidth
                                                            onChange={handleReferenceDataChange('reference_relation')}
                                                            placeholder='Enter relation'
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <InputLabel sx={{ color: '##72737C', marginTop: 2, fontWeight: '500', fontSize: '13px' }}>Organization</InputLabel>
                                                        <TextField
                                                            type='text'
                                                            variant='outlined'
                                                            fullWidth
                                                            onChange={handleReferenceDataChange('reference_organization')}
                                                            placeholder='Enter organization'
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </Stack>
                                            :
                                            null
                                    }
                                </Box>
                            </DialogContentText>
                    }
                </DialogContent>
                <DialogActions sx={{ padding: '16px' }}>
                    <Button variant='outlined' onClick={() => cancelApply()}>Cancel</Button>
                    <Box sx={{ flex: '1 1 auto' }} />

                    <LoadingButton
                        variant='contained'
                        type='submit'
                        loading={applyLoader}
                    >
                        Apply
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    )
}


export default function ApplyToJobModal(props) {

    const { candidateData, profileStrength, candLoader } = useContext(CandidateContext)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%', sm: '60%' },
        height: { xs: '90%', sm: '60%' },
        bgcolor: 'background.paper',
        borderRadius: '20px',
        boxShadow: 24,
        p: { xs: 2, sm: 4 },
        overflow: 'scroll',
        display: { xs: 'flex' },
        justifyContent: 'center'
    };

    const isCandidateValid = candidateData?.id !== 0;
    const hasSufficientStrength = profileStrength >= process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY;

    return (
        <>
            <React.Fragment>
                <Dialog
                    open={props.open}
                    onClose={props.handleClose}
                    scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    PaperProps={{
                        style: {
                            width: candidateData?.id !== 0 && !candLoader ? candidateData?.id !== 0 && !candLoader && profileStrength < process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY ? '700px' : '1000px' : '500px',
                            maxWidth: '1200px',
                        },
                    }}
                >
                    {candLoader ?
                        <Box className="" sx={style}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "18rem",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        </Box>
                        :
                        null
                    }

                    {
                        (isCandidateValid && (!!hasSufficientStrength) && (!candLoader)) ?
                            <ApplyJobForm props={props} />
                            :
                            null
                    }

                    {
                        (!!profileStrength && isCandidateValid && (!hasSufficientStrength) && (!candLoader)) ?
                            <ProfileStrengthLowMessage props={props} />
                            :
                            null
                    }

                    {
                        (!isCandidateValid && (!candLoader)) ?
                            <NonLoggedInMessage props={props} />
                            :
                            null
                    }
                </Dialog>
            </React.Fragment>
            {/* <SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} /> */}
        </>
    )
}