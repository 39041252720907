import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

function OpenAppComponent() {
    const params = useParams(); // assuming the job ID is available from the URL
    const [jobUrl, setJobUrl] = useState('');

    // useEffect(() => {
    //     // Generate the dynamic URL based on the job ID
    //     const generatedUrl = `https://atb-jobs.com/jobs/${params.job_id}`;
    //     setJobUrl(generatedUrl);
    // }, [params]);

    useEffect(() => {
        if (params.job_id) {
          // Set or update the "al:android:url" meta tag
          const androidUrlMeta = document.querySelector('meta[property="al:android:url"]');
          
          if (androidUrlMeta) {
            // Update the meta tag if it exists
            androidUrlMeta.setAttribute('content', `https://atb-jobs.com/jobs/${params.job_id}`);
          } else {
            // Create and append the meta tag if it doesn't exist
            const newMetaTag = document.createElement('meta');
            newMetaTag.setAttribute('property', 'al:android:url');
            newMetaTag.setAttribute('content', `https://atb-jobs.com/jobs/${params.job_id}`);
            document.head.appendChild(newMetaTag);
          }
    
          // Other required meta tags
          const packageMeta = document.querySelector('meta[property="al:android:package"]');
          if (!packageMeta) {
            const packageMetaTag = document.createElement('meta');
            packageMetaTag.setAttribute('property', 'al:android:package');
            packageMetaTag.setAttribute('content', 'com.candidate.atb_jobs');
            document.head.appendChild(packageMetaTag);
          }
    
          const appNameMeta = document.querySelector('meta[property="al:android:app_name"]');
          if (!appNameMeta) {
            const appNameMetaTag = document.createElement('meta');
            appNameMetaTag.setAttribute('property', 'al:android:app_name');
            appNameMetaTag.setAttribute('content', 'atB Jobs');
            document.head.appendChild(appNameMetaTag);
          }
        }
      }, [params.job_id]); // Run effect when jobId changes
    

    return (
        <div>
            {/* <Helmet>
                <meta property="al:android:url" content={jobUrl} />
                <meta property="al:android:package" content="com.candidate.atb_jobs" />
                <meta property="al:android:app_name" content="atB Jobs" />
            </Helmet> */}
        </div>
    );
}

export default OpenAppComponent;
