
import React, { useRef, useState, useEffect } from 'react';
import { TextField, Grid, InputLabel, Box, Divider, Button, Typography, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Skeleton } from '@mui/material';
import { fetchIpAddress, handleKeyDown, validatePhoneNumber } from "../../../helpers/helperFunctions";
import { candidateRegisterFlow, stepperStepInfo } from '../../../../fetchAPI';
import RequiredAsterisk from '../../CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk';
import { makeStyles } from "@material-ui/core/styles";
import { useAlert } from "../../../context/AlertContext";
import { defaultMobileNumber } from '../../../helpers/helperFunctions';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const useStyles = makeStyles({
    input: {
      borderLeft: '1px solid #C4C4C4',
      paddingLeft: "14px",
      marginLeft: "7px",
      padding: "30px 0px",
    },
    outlinedInput: {
      '& .react-tel-input .form-control': {
        borderRadius: '4px',
        borderColor: '#C4C4C4',
        padding: '14px',
        fontSize: '16px',
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '& .react-tel-input .flag-dropdown': {
        border: 'none',
        backgroundColor: 'transparent',
      },
      '& .react-tel-input .form-control:focus': {
        borderColor: '#0275D8',
        boxShadow: '0 0 0 1px #0275D8',
      },
      '& .react-tel-input .selected-flag': {
        marginLeft: '8px',
        marginRight: '12px',
      },
      '& .react-tel-input .country-list': {
        zIndex: 1000,
      },
    }
  });

export default function ReferenceInformation({ handleNext, handleBack, job_id, candidateData, candLoader, settingCandidateData, setIsFillup }) {
    const referenceNameRef = useRef('')
    const designationNameRef = useRef('')
    const organizationNameRef = useRef('')
    const relationNameRef = useRef('')
    const emailRef = useRef('')
    const mobileNoRef = useRef('')
    const [phoneNumber, setPhoneNumber] = useState("");
    const [country, setCountry] = useState("bd");
    const [loading, setLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [referenceInfo, setReferenceInfo] = useState({})
    const alertContext = useAlert();
    const [saveButtonHoverEffect, setSaveButtonHoverEffect] = useState("outlined");
    const [isBD, setIsBD] = useState(false);
    const [dialCode, setDialCode] = useState("");

    const classes = useStyles();

    useEffect(() => {
        if (candidateData.references) {
            setReferenceInfo(candidateData.references?.length !== 0 ? candidateData.references[0] : {})
        }
    }, [candidateData])

    useEffect(() => {
        if(referenceInfo?.dial_code && referenceInfo?.mobile_number) {
          setPhoneNumber(referenceInfo?.dial_code + referenceInfo?.mobile_number)
        }
    }, [referenceInfo.dial_code, referenceInfo.mobile_number])

    useEffect(() =>{      
        if(Object.keys(referenceInfo).length !== 0) {
            setIsFillup((prev) => ({...prev, reference:true}))
        }
    }, [referenceInfo, setIsFillup])
    
    const handlePhoneChange = (value, countryData) => {
        setDialCode(countryData.dialCode); 

        if(country !== countryData.countryCode) {
            setPhoneNumber('');
            setCountry(countryData.countryCode);
        }
        
        if(countryData.countryCode === "bd"){
            setIsBD(true)
        } else {
            setIsBD(false)
        }
        setPhoneNumber(value)
    };

    const handleSubmit = () => {
        let actualNumber;
        if(dialCode) {
            actualNumber = phoneNumber.replace(`${dialCode}`, isBD ? "0" : "")
        } else {
            actualNumber = referenceInfo?.mobile_number
        }

        if (isBD && actualNumber != "0"  && !validatePhoneNumber(actualNumber)) {
            alertContext.setOpenErrorAlert(true);
            alertContext.setResponseMessage("Please enter a valid phone number!");
            return;
        }

        if(referenceNameRef.current?.value && designationNameRef.current?.value && emailRef.current?.value) {
            setIsFillup((prev) => ({...prev, reference: true}))
        }
        
        setLoading(true)
        const paramData = {
            reference_id: referenceInfo.id ? referenceInfo.id : '',
            name: referenceNameRef.current?.value,
            designation: designationNameRef.current?.value,
            relation: relationNameRef.current?.value,
            email: emailRef.current?.value,
            organization: organizationNameRef.current?.value,
            mobile_number: actualNumber != "0" ? actualNumber : "",
            dialCode: dialCode ? dialCode : referenceInfo?.dial_code
        }

        candidateRegisterFlow('reference', job_id, paramData)
            .then((response) => {
                if (response && response.success) {
                    handleNext()
                } else {
                    setResponseMessage(response.errors[0]?.message)
                }
            }).finally(() => setLoading(false), setResponseMessage(''))

        const ip = fetchIpAddress();
        const platform = "web";
        const stepNo = 5;

        stepperStepInfo(stepNo, ip, platform).then((response) => {
        if(response && response.success) {
            setLoading(false);
            }
        })

    }

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Typography align='left' variant='h6' sx={{ fontWeight: 600 }}>Reference Information</Typography>
            </Box>
            <Divider />
            {
                candLoader ?
                    <div className='mt-3 col-lg-12 col-md-12'>
                        <Skeleton variant="text" height={60} />
                        <Skeleton variant="text" height={50} />
                        <div className='text-left'>
                            <Skeleton variant="text" />
                        </div>
                        <div className='mt-3 text-left'>
                            <Skeleton variant="rounded" height={120} />
                        </div>
                        <div className='text-left'>
                            <Skeleton variant="text" width={80} height={50} />
                        </div>
                    </div>
                    :
                    <form
                        className="ga_stepper_candidate_register_academic_qualification_form" // do not remove ga_ class
                        onSubmit={(e) => [e.preventDefault(), handleSubmit()]}
                    >
                        {responseMessage ?
                            <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                {
                                    responseMessage?.map((item, index) => {
                                        return (
                                            <center key={index}>
                                                <small style={{ color: 'red' }}> {item} </small>
                                            </center>
                                        )
                                    })
                                }
                            </div>
                            : ''}
                        <Grid container spacing={2} mt={3}>
                            <Grid item lg={6} xs={12}>
                                <InputLabel sx={{ fontWeight: 'bold' }}>Enter Name <RequiredAsterisk /></InputLabel>
                                <TextField
                                    required
                                    type='text'
                                    name='reference_name'
                                    variant='outlined'
                                    placeholder='Enter Your Reference Name'
                                    fullWidth
                                    inputRef={referenceNameRef}
                                    defaultValue={referenceInfo.name}
                                    inputProps={{
                                        style: {
                                            padding: "10px 14px",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <InputLabel sx={{ fontWeight: 'bold' }}>Designation <RequiredAsterisk /></InputLabel>
                                <TextField
                                    required
                                    type='text'
                                    name='reference_designation'
                                    variant='outlined'
                                    placeholder='Enter Your Reference Designation'
                                    fullWidth
                                    inputRef={designationNameRef}
                                    defaultValue={referenceInfo.designation}
                                    inputProps={{
                                        style: {
                                            padding: "10px 14px",
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                            <Grid item lg={6} xs={12}>
                                <InputLabel sx={{ fontWeight: 'bold' }}>Email Address <RequiredAsterisk /></InputLabel>
                                <TextField
                                    required
                                    type='email'
                                    name='reference_email'
                                    variant='outlined'
                                    placeholder='Enter Your Reference Email Address'
                                    fullWidth
                                    inputRef={emailRef}
                                    defaultValue={referenceInfo.email}
                                    inputProps={{
                                        style: {
                                            padding: "10px 14px",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <InputLabel sx={{ fontWeight: 'bold' }}>Relation</InputLabel>
                                <TextField
                                    type='text'
                                    name='reference_relation'
                                    variant='outlined'
                                    placeholder='Enter Your Reference Relation'
                                    fullWidth
                                    inputRef={relationNameRef}
                                    defaultValue={referenceInfo.relation}
                                    inputProps={{
                                        style: {
                                            padding: "10px 14px",
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={1}>
                            <Grid item lg={6} xs={12}>
                                <InputLabel sx={{ fontWeight: 'bold' }}>Organization </InputLabel>
                                <TextField
                                    type='text'
                                    name='reference_organization'
                                    variant='outlined'
                                    placeholder='Enter Your Reference Organization'
                                    fullWidth
                                    inputRef={organizationNameRef}
                                    defaultValue={referenceInfo.organization}
                                    inputProps={{
                                        style: {
                                            padding: "10px 14px",
                                        },
                                    }}
                                />
                            </Grid>
                            
                            <Grid item lg={6} xs={12}>
                                <InputLabel sx={{ fontWeight: 'bold' }}>Mobile Number </InputLabel>
                                <PhoneInput
                                    country={country}
                                    value={phoneNumber || ''}
                                    onChange={handlePhoneChange}
                                    inputProps={{
                                        name: 'mobile_number',
                                        required: true,
                                        // autoFocus: true,
                                    }}
                                    countryCodeEditable={false}
                                    enableSearch={true}
                                    containerClass={classes.outlinedInput}
                                    inputStyle={{
                                        width: `calc(100% - 20px)`,
                                        border: '1px solid #C4C4C4',
                                        borderRadius: '4px',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        outline: 'none',
                                        paddingTop: '20.5px',
                                        paddingBottom: "20.5px",
                                        marginLeft: "20px",
                                    }}
                                      searchStyle={{width: "90%"}}
                                      dropdownStyle={{width: `${window.innerWidth > 560 ? "600%" : "400%"}`}}
                                    />
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", pt: 4 }}>
                            <Button
                                variant='outlined'
                                // disabled
                                sx={{ mr: 1, minWidth: { sm: "102px" } }}
                                onClick={handleBack}
                            >
                                Back
                            </Button>
                            <Box sx={{ display: "flex" }}>
                                <LoadingButton
                                    sx={{ ml: { xs: 1, md: 2, minWidth: { sm: "102px" } } }}
                                    variant="contained"
                                    loading={loading}
                                    type="submit"
                                >
                                    Continue
                                </LoadingButton>
                            </Box>
                        </Box>
                    </form>
            }
        </>
    )
}
