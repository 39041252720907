

const BASE = process.env.REACT_APP_API_SERVER_BASE_URL;


export const DR_MESSAGE_SUMMARY_API = `${BASE}/dr/chats/summary`;
export const DR_MESSAGE_SEND_API = `${BASE}/dr/message/send`;
export const DR_MESSAGE_GET_API = `${BASE}/dr/messages`;
export const DR_MESSAGE_READ_API = `${BASE}/dr/messages/read`;
// export const GET_DIRECT_SCOUTING_STAT_API = `${BASE}/dr/message/jobwiseStat`;

