import React, { useContext } from "react";
import styles from '../CandidateUpdateProfile.module.css'
import { Box, Typography } from "@mui/material";
import CommonMasking from "../../../../maskingcomponents/CommonMasking";
import { CandidateContext } from "../../../../../context/CandidateContext";

export default function SinglePersonalInfoBlock(props) {
    const {profileStrength} = useContext(CandidateContext)

    return (
        <>
            <Box
                sx={{
                    display: {xs: "none", sm: "none", md: "flex"},
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#424447',
                    mb: "10px",
                }}
            >
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Typography sx={{fontFamily: 'Poppins', minWidth: {md: profileStrength < 100 ? "250px" : "300px", lg: "300px"}}}>
                        {props.title}
                    </Typography>
                    <Box component={"span"} sx={{fontWeight: 700, fontFamily: 'Poppins'}}>:</Box>
                </Box>
                <Box sx={{marginLeft: {md: profileStrength < 100 ? "10px" : "50px", lg: "50px"}}}>
                    {props.masking ? <CommonMasking>Personal Information</CommonMasking> : <>{props.value}</>}
                </Box>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, marginBottom: '10px' }}>
                <div className={styles.personalInfoTitleSmallDevice}>{props.title}</div>
                <div className={styles.personalInfoValueSmallDevice}>
                    {props.masking ? <CommonMasking>Personal Information</CommonMasking> : <>{props.value || '--'}</>}
                </div>
            </Box>
        </>
    )
}