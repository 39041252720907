import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Drawer } from "@mui/material";
import MessageBox from "./MessageBox";
import { useDrInbox } from "../contexts/DrInboxContextProvider";

function MessageBoxDrawer({ openDrawer, setOpenDrawer }) {
  const handleClose = () => {
    setOpenDrawer(false);
  };

  const { state, dispatch } = useDrInbox();

  const handleRefresh = (e) => {
    if (state.currentChatId != null) {
      dispatch({ type: "getNextMessage" });
    }
  };

  // console.log(window.innerWidth);
  return (
    <Drawer
      open={openDrawer && window.innerWidth < 900}
      // open={openDrawer}
      onClose={handleClose}
      anchor="right"
    >
      <Box sx={{ width: { xs: "100vw", sm: "75vw" } }}>
        <Box
          className="bg-[var(--app-button-bg-color-light-primary)] p-2"
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Box
            sx={{ cursor: "pointer", display: "inline-block", py: 2 }}
            onClick={handleClose}
          >
            <KeyboardBackspaceIcon
              sx={{ color: "var(--app-text-color-primary)" }}
            />
          </Box>
          <Box className="app-heading-primary-md">
            {
              state.data?.chats?.filter((item) => {
                return item.chatId == state.currentChatId;
              })[0]?.jobTitle || ''
            }
          </Box>
        </Box>
        <Box>
          <MessageBox />
        </Box>
      </Box>
    </Drawer>
  );
}

export default MessageBoxDrawer;
