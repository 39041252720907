import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Grid, InputLabel, Skeleton, Stack, TextField, } from '@mui/material';
import { getCandidateRecommendedJobInfo, getCurrencies, postRecommendedJobs } from '../../../../fetchAPI';
import Select from 'react-select'
import { DropdownContext } from '../../../context/DropdownContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(4)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        maxWidth: '1600px',
        maxHeight: '800px',
        overflowY: 'unset',
        width: '850px !important',
        '@media (max-width: 650px)': {
            width: '100% !important',
        },
    },
}));

export default function RecommendedJobsModal(props) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [recommendation, setRecommendation] = React.useState({
        designation: '',
        expected_salary: null,
        employment_type_name: null,
        employment_type: null,
        location: null,
        district: null,
        currency: null,
        currency_id: null
    });
    const [currencies, setCurrencies] = React.useState('');

    const { types, district, apiCallingConditionSet, setApiCallingConditionSet } = React.useContext(DropdownContext);
    React.useEffect(() => {
        setApiCallingConditionSet({ ...apiCallingConditionSet, type: true, district: true })

    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (name) => (event) => {
        setRecommendation({ ...recommendation, [name]: event.target.value });
    };

    const handleChangeSelect = (name) => (selectedOption) => {
        setRecommendation({ ...recommendation, [name]: selectedOption.value });
    };

    const getCurrecyData = () => {
        getCurrencies().then((response) => {
            if (response && response.success) {
                let responseData = response.response.data
                setCurrencies([{ id: -1, name: 'Default' }, ...responseData])

            }
            else {
                // console.log('errors: ', response);
            }
        })
    }
    const getRecommendedJobInfoData = () => {
        setLoading(true);
        getCandidateRecommendedJobInfo().then((response) => {
            if (response && response.success) {
                if (response?.response?.data === null) {
                    setRecommendation({
                        designation: '',
                        expected_salary: null,
                        employment_type_name: null,
                        employment_type: null,
                        location: null,
                        district: null,
                        currency: null,
                        currency_id: null
                    })
                }
                else {
                    setRecommendation({
                        designation: response.response?.data.designation === null ? '' : response.response?.data.designation,
                        expected_salary: response.response?.data.expected_salary,
                        employment_type_name: response.response?.data.candidate_type === null ? 'None' : response.response?.data.candidate_type.name,
                        employment_type: response.response?.data.candidate_type_id,
                        location: response.response?.data.district_id,
                        district: response.response?.data.district === null ? null : response.response?.data.district.name,
                        currency: response.response?.data.currency === null ? 'Default' : response.response?.data.currency.name,
                        currency_id: response.response?.data.currency_id
                    })
                }
            }
            else {
                // console.log('errors: ', response);
            }
        }).finally(() => setLoading(false))
    }

    React.useEffect(() => {
        getCurrecyData()
        getRecommendedJobInfoData()
    }, [open])

    function submitRecommendedJobs(e) {
        e.preventDefault();
        setLoading(true)
        postRecommendedJobs(recommendation).then(response => {
            if (response && response.success) {
                // Do something with the response
                props.APIcall();
                setLoading(false)
            } else {
                // Handle the case where response fetching failed
                console.log('Data fetching failed. Please try again later.');
            }
        });
    }

    return (
        <React.Fragment>
            <Button
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "#0275D8", fontFamily: "Epilogue", fontWeight: "600 !important", fontSize: "16px !important", backgroundColor: "#E5F5FF", padding: "6px 16px !important", cursor: "pointer", ":hover": {backgroundColor: "#E5F5FF", boxShadow: 3}, minWidth: "94px", height: "36px", ":focus": {outline: "0px"} }}
                onClick={handleClickOpen}
            >
                <Typography sx={{color: "#0275D8", marginRight: "10px", fontFamily: "Epilogue", fontSize: {xs: "14px", sm: "16px"}, fontWeight: 600, textTransform: "capitalize", lineHeight: 1.5, mt: "3px"}}>Add</Typography>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="20" height="20" rx="10" fill="#CAEBFF"/>
                    <path d="M6.99362 9.80457C6.27657 9.80457 5.56145 9.80457 4.8444 9.80457C4.40448 9.80457 4.12153 10.0836 4.13316 10.4848C4.13603 10.6259 4.18635 10.7619 4.27601 10.8709C4.36567 10.9799 4.48944 11.0556 4.62735 11.0856C4.72962 11.1044 4.83344 11.1135 4.93742 11.1127H9.07696C9.38122 11.1127 9.34052 11.072 9.34246 11.386C9.34246 12.7852 9.34246 14.1844 9.34246 15.5836C9.34246 16.041 9.61378 16.3298 10.0188 16.3201C10.1598 16.3184 10.2961 16.2693 10.4058 16.1807C10.5155 16.0921 10.5921 15.9692 10.6235 15.8317C10.6438 15.7296 10.6535 15.6257 10.6525 15.5216C10.6525 14.1224 10.6525 12.7212 10.6525 11.322C10.6525 11.1495 10.6952 11.105 10.8677 11.1069C12.2669 11.1069 13.6661 11.1069 15.0673 11.1069C15.1647 11.1089 15.2621 11.1011 15.358 11.0836C15.5021 11.0525 15.6313 10.9729 15.7239 10.8582C15.8166 10.7434 15.8671 10.6003 15.8671 10.4528C15.8671 10.3053 15.8166 10.1623 15.7239 10.0475C15.6313 9.93272 15.5021 9.85314 15.358 9.82201C15.2558 9.80289 15.1518 9.79445 15.0479 9.79682H10.9277C10.6099 9.79682 10.6545 9.84139 10.6545 9.51388C10.6545 8.14178 10.6545 6.7684 10.6545 5.39372C10.6564 5.29629 10.6487 5.1989 10.6312 5.10302C10.6019 4.9568 10.5228 4.82525 10.4075 4.73073C10.2921 4.63621 10.1476 4.58456 9.99847 4.58456C9.84934 4.58456 9.70481 4.63621 9.58945 4.73073C9.4741 4.82525 9.39504 4.9568 9.36572 5.10302C9.34988 5.19908 9.34275 5.29638 9.3444 5.39372C9.3444 6.79294 9.3444 8.19411 9.3444 9.59333C9.3444 9.75612 9.30177 9.8007 9.13897 9.79876C8.4258 9.80457 7.70874 9.80457 6.99362 9.80457Z" fill="#0079C1"/>
                    </svg>
                </Box>
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Recommended Jobs
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <form onSubmit={submitRecommendedJobs}>
                    {
                        loading ?
                            <DialogContent dividers>
                                {/* Recommended Jobs Text Fields */}
                                <Grid container spacing={2} alignItems={'center'}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Title/Designation</InputLabel>
                                        <Skeleton sx={{ marginTop: 2 }} variant='text' width={'100%'} height={'40px'} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Expected Salary</InputLabel>
                                        <Grid container spacing={0} alignItems={'center'}>
                                            <Grid item md={3} xs={6}>
                                                <Skeleton sx={{ marginTop: 2 }} variant='text' width={'100%'} height={'40px'} />
                                            </Grid>
                                            <Grid item md={9} xs={6}>
                                                <Skeleton sx={{ marginTop: 2 }} variant='text' width={'100%'} height={'40px'} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} mt={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Employment Type</InputLabel>
                                        <Skeleton sx={{ marginTop: 2 }} variant='text' width={'100%'} height={'40px'} />

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Location</InputLabel>
                                        <Skeleton sx={{ marginTop: 2 }} variant='text' width={'100%'} height={'40px'} />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            :
                            <DialogContent dividers sx={{overflowY:'unset'}}>
                                {/* Recommended Jobs Text Fields */}
                                <Grid container spacing={2} alignItems={'center'}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Title/Designation</InputLabel>
                                        <TextField
                                            id="outlined-basic"
                                            placeholder="Type Designation"
                                            size='small'
                                            variant="outlined"
                                            value={recommendation?.designation}
                                            fullWidth
                                            sx={{ marginTop: 1, fontSize: '14px', padding: '8px 0px' }}
                                            onChange={handleChange('designation')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Expected Salary</InputLabel>
                                        <Grid container spacing={0} alignItems={'center'}>
                                            <Grid item md={5} xs={6}>
                                                <Select
                                                    placeholder="Select Currency"
                                                    defaultValue={recommendation?.currency_id ? { label: recommendation?.currency, value: recommendation?.currency_id }: null}
                                                    options=
                                                    {
                                                        currencies ?
                                                            currencies.map(currency => {
                                                                return (
                                                                    {
                                                                        label: currency.name,
                                                                        value: currency.id
                                                                    }
                                                                )
                                                            })
                                                            :
                                                            null
                                                    }
                                                    onChange={handleChangeSelect('currency_id')}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            fontSize: '12px',
                                                            marginTop: 8,

                                                        }),

                                                        // Add other style overrides as needed
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={7} xs={6}>
                                                <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    size='small'
                                                    fullWidth
                                                    defaultValue={recommendation?.expected_salary}
                                                    placeholder='Type Expected Salary'
                                                    sx={{ marginTop: 1, padding: '8px 0px', fontSize: '14px' }}
                                                    onChange={handleChange("expected_salary")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} mt={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Employment Type</InputLabel>
                                        <Select
                                            placeholder="Select Type"
                                            defaultValue={recommendation?.employment_type ? { label: recommendation?.employment_type_name, value: recommendation?.employment_type }: null}
                                            options=
                                            {
                                                types ?
                                                    types.map(type => {
                                                        return (
                                                            {
                                                                label: type.name,
                                                                value: type.id
                                                            }
                                                        )
                                                    })
                                                    :
                                                    null
                                            }
                                            onChange={handleChangeSelect('employment_type')}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    fontSize: '14px',
                                                    marginTop: 8,

                                                }),

                                                // Add other style overrides as needed
                                            }}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel>Location</InputLabel>
                                        <Select
                                            placeholder="Select Location"
                                            defaultValue={recommendation?.location ? { label: recommendation?.district, value: recommendation?.location }: null}
                                            options=
                                            {
                                                district ?
                                                    district.map(type => {
                                                        return (
                                                            {
                                                                label: type.name,
                                                                value: type.id
                                                            }
                                                        )
                                                    })
                                                    :
                                                    null
                                            }
                                            onChange={handleChangeSelect('location')}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    fontSize: '14px',
                                                    marginTop: 8,

                                                }),

                                                // Add other style overrides as needed
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                    }

                    <DialogActions>
                        <Box m={2}>
                            <Button
                                variant='outlined'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                variant='contained'
                                onClick={handleClose}
                                sx={{
                                    marginLeft: 1
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        </React.Fragment >
    );
}