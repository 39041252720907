import React, { useState } from "react";
import { Box, Button, Card, Grid, Skeleton, Stack, Tab, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { JobSearchContext } from "../../../context/JobSearchContext";

function BenefitItemIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#E5F5FF" />
            <path d="M8.60938 12.7976L10.5786 14.7668L15.5017 9.84375" stroke="#15A449" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}


export default function BenefitWebSection({ benefitData, benefitDataTotalCount }) {

    const { t } = useTranslation(["homepage", "banner"])
    const [value, setValue] = React.useState(1);
    const { setCheckedJobBenefitList, setCheckedEmpBenefitList, setJobSearchData, jobSearchData } = React.useContext(JobSearchContext)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const handleSearch = (id) => {
    //     if (value === 1 || value === 2) {
    //         setCheckedJobBenefitList([id])
    //     } else {
    //         setCheckedEmpBenefitList([id])
    //         setJobSearchData({ ...jobSearchData, employer_benefit: [id].toString() })
    //     }
    // }

    return (
        <Box className='container' sx={{ marginTop: "40px" }}>

            {/* Title */}
            <Stack
                spacing={2}
                direction={'row'}
                p={2}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Typography
                    variant='h3'
                    sx={{
                        color: '#424447',
                        fontWeight: 700,
                        fontSize: { xs: '20px', sm: '20px', lg: '28px' },
                    }}>
                    {t("homepage:search-by-benefit.title")}
                    <span className='' style={{ color: '#DB1616' }}> &nbsp;({Number(benefitDataTotalCount).toLocaleString()})</span>
                </Typography>

                <Link
                    to={{
                        pathname: '/search/jobs',
                        state: { fromHome: true }
                    }}
                >
                    <Button className='d-none d-lg-block' size='large' variant='outlined'>{t("button:explore-all.title")}</Button>
                </Link>
            </Stack>

            {/* Tabs */}
            <Box m={2}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList variant="fullWidth" onChange={handleChange} aria-label="lab API tabs example">
                            {
                                benefitData?.categories.map((data, index) => {
                                    return (
                                        <Tab
                                            key={index}
                                            label={data.name}
                                            value={data?.id}
                                            icon={data?.svg_icon}
                                            iconPosition="start"
                                            textColor="white"
                                            sx={{
                                                backgroundColor: '#E5F5FF',
                                                borderTopRightRadius: 10,
                                                borderTopLeftRadius: 10,
                                                minHeight: '30px',
                                                paddingTop: '6px',
                                                paddingBottom: '6px',
                                                marginRight: 3,
                                                textTransform: 'capitalize',
                                                ":focus": { outline: "none" },
                                                "&.Mui-selected": {
                                                    backgroundColor: '#0079C1',
                                                    color: 'white',
                                                }
                                            }}
                                        />
                                    )
                                })
                            }
                        </TabList>
                    </Box>
                    <TabPanel value={value} >
                        <Grid container spacing={2}>
                            {
                                benefitData.category_options
                                    .filter(option => option.category_id === value)
                                    .slice(0, 12)
                                    .map((option, index) => (
                                        <Grid key={index} item md={3}>
                                            <Link
                                                to={{
                                                    pathname: '/search/jobs',
                                                    search: value == 1 || value == 2 ? `?job_benefit=${option.id}` : `?employer_benefit=${option.id}`,
                                                    state: { fromHome: true }
                                                }}
                                            // onClick={() => handleSearch(option.id)}
                                            >
                                                <Card
                                                    sx={{
                                                        padding: 2,
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        height: '100%'
                                                    }}
                                                >
                                                    <Stack direction={'row'} spacing={2}>
                                                        <BenefitItemIcon />
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                fontSize: '13px',
                                                                fontWeight: '400'
                                                            }}
                                                        >
                                                            {option.title}
                                                        </Typography>
                                                    </Stack>
                                                </Card>
                                            </Link>
                                        </Grid>
                                    ))
                            }
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Box>
        </Box>
    )
}