import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Stack, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { JobSearchContext } from "../../../context/JobSearchContext";

function BenefitItemIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#E5F5FF" />
            <path d="M8.60938 12.7976L10.5786 14.7668L15.5017 9.84375" stroke="#15A449" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default function BenefitMobileSection({ benefitData, benefitDataTotalCount, svgIcons }) {

    const { t } = useTranslation(["homepage", "banner"])
    const [categoryIndex, setCategoryIndex] = useState()
    const { setCheckedJobBenefitList, setCheckedEmpBenefitList, setJobSearchData, jobSearchData } = React.useContext(JobSearchContext)

    // const handleSearch = (id) => {
    //     if (categoryIndex === 0 || categoryIndex === 1) {
    //         setCheckedJobBenefitList([id])
    //     } else {
    //         setCheckedEmpBenefitList([id])
    //         setJobSearchData({ ...jobSearchData, employer_benefit: [id].toString() })
    //     }
    // }

    return (
        <Box className='container'>

            {/* Title */}
            <Box sx={{ marginTop: "40px", marginBottom: 2 }}>
                <Typography
                    variant='h3'
                    align="center"
                    sx={{
                        color: '#424447',
                        fontWeight: 700,
                        fontSize: { xs: '20px', sm: '20px', lg: '28px' },
                    }}>
                    {t("homepage:search-by-benefit.title")}
                    <span className='' style={{ color: '#DB1616' }}> &nbsp;({Number(benefitDataTotalCount).toLocaleString()})</span>
                </Typography>
            </Box>

            {/* Accordion */}
            <Box p={2}>
                {
                    benefitData?.map((benefit, categoryIndex) => {
                        const iconKey = `svg_${categoryIndex + 1}`;
                        const svg_icon = svgIcons[categoryIndex][iconKey];
                        return (
                            <Accordion key={categoryIndex} sx={{ boxShadow: 0, my: 1 }}>
                                <AccordionSummary
                                    onClick={() => setCategoryIndex(categoryIndex)}
                                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        backgroundColor: '#0079C1',
                                        color: 'white',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Stack direction={'row'} spacing={0} alignItems={'center'}>
                                        {svg_icon}
                                        {benefit.name}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: 0 }}>
                                    <Stack spacing={2}>
                                        {
                                            benefit?.options
                                                .slice(0, 8)
                                                .map((data, index) => {
                                                    return (
                                                        <Link
                                                        to={{
                                                            pathname: '/search/jobs',
                                                            search: categoryIndex == 0 || categoryIndex == 1 ? `?job_benefit=${data.id}` : `?employer_benefit=${data.id}`,
                                                            state: { fromHome: true }
                                                        }}
                                                    // onClick={() => handleSearch(option.id)}
                                                    >
                                                            <Card key={index} sx={{ padding: 2 }}>
                                                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                                                    <BenefitItemIcon />
                                                                    <Typography
                                                                        variant="body1"
                                                                        sx={{
                                                                            fontSize: '13px',
                                                                            fontWeight: '400'
                                                                        }}
                                                                    >
                                                                        {data.title}
                                                                    </Typography>
                                                                </Stack>
                                                            </Card>
                                                        </Link>
                                                    )
                                                })
                                        }
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }

            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Link
                    to={{
                        pathname: '/search/jobs',
                        state: { fromHome: true }
                    }}
                >
                    <Button size='large' variant='outlined'>{t("button:explore-all.title")}</Button>
                </Link>
            </Box>
        </Box>
    )
}
